/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base', './colibrio-core-selector-base'], function (a0, a1, a2) { return f(r, a0, a1, a2); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base'), require('./colibrio-core-selector-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.selector = r.colibrio.core.selector || {}; r.colibrio.core.selector.epubcfi = f(r, colibrio.core.base, colibrio.core.io.base, colibrio.core.selector.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_selector_base) { var api = {}; (function() { 'use strict';var l;function aa(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function ca(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:aa(a)}}function u(a){if(!(a instanceof Array)){a=ca(a);for(var b,c=[];!(b=a.next()).done;)c.push(b.value);a=c}return a}var da="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},ea;
if("function"==typeof Object.setPrototypeOf)ea=Object.setPrototypeOf;else{var fa;a:{var ha={v:!0},ia={};try{ia.__proto__=ha;fa=ia.v;break a}catch(a){}fa=!1}ea=fa?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var ja=ea;
function ka(a,b){a.prototype=da(b.prototype);a.prototype.constructor=a;if(ja)ja(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.F=b.prototype}var la="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,ma="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};
function na(a,b){if(b){var c=la;a=a.split(".");for(var d=0;d<a.length-1;d++){var e=a[d];e in c||(c[e]={});c=c[e]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&ma(c,a,{configurable:!0,writable:!0,value:b})}}
na("Promise",function(a){function b(f){this.c=0;this.h=void 0;this.a=[];var h=this.f();try{f(h.resolve,h.reject)}catch(k){h.reject(k)}}function c(){this.a=null}function d(f){return f instanceof b?f:new b(function(h){h(f)})}if(a)return a;c.prototype.c=function(f){if(null==this.a){this.a=[];var h=this;this.f(function(){h.h()})}this.a.push(f)};var e=la.setTimeout;c.prototype.f=function(f){e(f,0)};c.prototype.h=function(){for(;this.a&&this.a.length;){var f=this.a;this.a=[];for(var h=0;h<f.length;++h){var k=
f[h];f[h]=null;try{k()}catch(m){this.g(m)}}}this.a=null};c.prototype.g=function(f){this.f(function(){throw f;})};b.prototype.f=function(){function f(m){return function(n){k||(k=!0,m.call(h,n))}}var h=this,k=!1;return{resolve:f(this.A),reject:f(this.g)}};b.prototype.A=function(f){if(f===this)this.g(new TypeError("A Promise cannot resolve to itself"));else if(f instanceof b)this.B(f);else{a:switch(typeof f){case "object":var h=null!=f;break a;case "function":h=!0;break a;default:h=!1}h?this.o(f):this.i(f)}};
b.prototype.o=function(f){var h=void 0;try{h=f.then}catch(k){this.g(k);return}"function"==typeof h?this.D(h,f):this.i(f)};b.prototype.g=function(f){this.j(2,f)};b.prototype.i=function(f){this.j(1,f)};b.prototype.j=function(f,h){if(0!=this.c)throw Error("Cannot settle("+f+", "+h+"): Promise already settled in state"+this.c);this.c=f;this.h=h;this.l()};b.prototype.l=function(){if(null!=this.a){for(var f=0;f<this.a.length;++f)g.c(this.a[f]);this.a=null}};var g=new c;b.prototype.B=function(f){var h=this.f();
f.m(h.resolve,h.reject)};b.prototype.D=function(f,h){var k=this.f();try{f.call(h,k.resolve,k.reject)}catch(m){k.reject(m)}};b.prototype.then=function(f,h){function k(q,r){return"function"==typeof q?function(v){try{m(q(v))}catch(t){n(t)}}:r}var m,n,p=new b(function(q,r){m=q;n=r});this.m(k(f,m),k(h,n));return p};b.prototype.catch=function(f){return this.then(void 0,f)};b.prototype.m=function(f,h){function k(){switch(m.c){case 1:f(m.h);break;case 2:h(m.h);break;default:throw Error("Unexpected state: "+
m.c);}}var m=this;null==this.a?g.c(k):this.a.push(k)};b.resolve=d;b.reject=function(f){return new b(function(h,k){k(f)})};b.race=function(f){return new b(function(h,k){for(var m=ca(f),n=m.next();!n.done;n=m.next())d(n.value).m(h,k)})};b.all=function(f){var h=ca(f),k=h.next();return k.done?d([]):new b(function(m,n){function p(v){return function(t){q[v]=t;r--;0==r&&m(q)}}var q=[],r=0;do q.push(void 0),r++,d(k.value).m(p(q.length-1),n),k=h.next();while(!k.done)})};return b});
na("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(c){return Promise.resolve(b()).then(function(){return c})},function(c){return Promise.resolve(b()).then(function(){throw c;})})}});var oa=__dep__colibrio_core_base;var w=__dep__colibrio_core_selector_base;function x(a){return 0<a.length?a[a.length-1]:void 0};function y(a){return a&&9===a.nodeType}function z(a){return a&&1===a.nodeType}function pa(a,b,c){Array.isArray(a)?b=[]:b||(b={});if("object"===typeof a&&null!==a)for(var d=Object.keys(a),e=0;e<d.length;e++){var g=d[e],f=a[g];null!==f&&"object"===typeof f?b[g]=pa(f,b[g],c):"undefined"===typeof f?c&&delete b[g]:b[g]=f}return b}var qa=Array.isArray;function A(a){return"number"===typeof a&&!isNaN(a)}function ra(a){return a&&!qa(a)&&"object"===typeof a};function B(a){for(var b=0;a=a.previousSibling;)b++;return b};var sa=new Uint16Array(1);sa[0]=4660;new Uint8Array(sa.buffer);function C(a,b){return oa.ColibrioError.call(this,"colibrio.core.selector.epubcfi",a,b)||this}ka(C,oa.ColibrioError);function D(a){switch(a.type){case "ASSERTION_GENERIC":return ta(a);case "ASSERTION_TEXT_LOCATION":return ua(a);case "ASSERTION_XML_ID":return wa(a)}}function E(a){return{indirection:a.indirection,srcOffset:a.srcOffset,steps:a.steps.map(function(b){return F(b)}),type:a.type}}
function G(a){switch(a.type){case "OFFSET_CHARACTER":return{assertion:a.assertion?D(a.assertion):null,characterOffset:a.characterOffset,srcOffset:a.srcOffset,type:a.type};case "OFFSET_SPATIAL":return{assertion:a.assertion?D(a.assertion):null,srcOffset:a.srcOffset,type:a.type,x:a.x,y:a.y};case "OFFSET_TEMPORAL":return{assertion:a.assertion?D(a.assertion):null,seconds:a.seconds,srcOffset:a.srcOffset,type:a.type,x:a.x,y:a.y}}}
function xa(a){return{complete:a.complete,localPaths:a.localPaths.map(function(b){return E(b)}),offset:a.offset?G(a.offset):null,srcOffset:a.srcOffset,type:a.type}}function H(a){return{errors:[],parentPath:a.parentPath?xa(a.parentPath):null,rangeEndPath:a.rangeEndPath?xa(a.rangeEndPath):null,rangeStartPath:a.rangeStartPath?xa(a.rangeStartPath):null,src:a.src,srcModified:a.srcModified,srcOffset:a.srcOffset,type:a.type}}
function F(a){switch(a.type){case "STEP_ELEMENT":return{assertion:a.assertion?wa(a.assertion):null,srcOffset:a.srcOffset,stepValue:a.stepValue,type:a.type};case "STEP_TEXT":return{assertion:a.assertion?D(a.assertion):null,srcOffset:a.srcOffset,stepValue:a.stepValue,type:a.type}}}function J(){return{complete:!1,localPaths:[],offset:null,type:"PATH"}}function ya(){return{indirection:!1,steps:[],type:"LOCAL_PATH"}}
function za(){return{errors:[],parentPath:null,rangeEndPath:null,rangeStartPath:null,src:"",srcModified:!1,type:"ROOT"}}function Aa(a){return{name:a.name,srcOffset:a.srcOffset,type:a.type,values:a.values.slice()}}function ta(a){return{parameters:a.parameters.map(function(b){return Aa(b)}),srcOffset:a.srcOffset,type:a.type}}function ua(a){return{parameters:a.parameters.map(function(b){return Aa(b)}),srcOffset:a.srcOffset,textAfter:a.textAfter,textBefore:a.textBefore,type:a.type}}
function wa(a){return{parameters:a.parameters.map(function(b){return Aa(b)}),srcOffset:a.srcOffset,type:a.type,xmlId:a.xmlId}};function Ba(a){var b=["epubcfi("];a.parentPath&&Ca(a.parentPath,b);if(a.rangeStartPath||a.rangeEndPath)b.push(","),a.rangeStartPath&&Ca(a.rangeStartPath,b),a.rangeEndPath&&(b.push(","),Ca(a.rangeEndPath,b));b.push(")");return b.join("")}
function Da(a,b){b.push("[");"ASSERTION_XML_ID"===a.type?a.xmlId&&b.push(K(a.xmlId)):"ASSERTION_TEXT_LOCATION"===a.type&&(a.textBefore&&b.push(K(a.textBefore)),a.textAfter&&b.push(",",K(a.textAfter)));for(var c=0;c<a.parameters.length;c++){var d=a.parameters[c];b.push(";",K(d.name));for(var e="=",g=0;g<d.values.length;g++)b.push(e,K(d.values[g])),e=","}b.push("]")}
function Ca(a,b){for(var c=0;c<a.localPaths.length;c++){var d=a.localPaths[c],e=b;d.indirection&&e.push("!");for(var g=0;g<d.steps.length;g++){var f=d.steps[g],h=e;h.push("/",f.stepValue);f.assertion&&Da(f.assertion,h)}}a.offset&&(a=a.offset,"OFFSET_CHARACTER"===a.type?b.push(":",a.characterOffset):"OFFSET_SPATIAL"===a.type?b.push("@",a.x,":",a.y):"OFFSET_TEMPORAL"===a.type&&(b.push("~",a.seconds),A(a.x)&&A(a.y)&&b.push("@",a.x,":",a.y)),a.assertion&&Da(a.assertion,b))}
function K(a){return a.replace(/[\^\[\]\(\),;=]/g,function(b){return"^"+b})};function Ea(a,b,c,d){var e=null;if(z(a)){var g=b<a.childNodes.length?a.childNodes[b]:null;z(g)?d=L(g,d):g?(d=L(a,d),d.push(Fa(g)),e=Ga(g)):(d=L(a,d),d.push({type:"STEP_ELEMENT",assertion:null,stepValue:0===b?0:2*a.childElementCount+2}))}else if(z(a.parentNode))a===d?d=null:(d=L(a.parentNode,d),d.push(Fa(a))),(e=Ga(a))&&a&&3===a.nodeType&&(e.characterOffset+=b);else throw new C("CONTAINER_NOT_ATTACHED_TO_DOCUMENT",""+a);c||(c=J());d&&c.localPaths.push({type:"LOCAL_PATH",indirection:0<c.localPaths.length,
steps:d});c.offset=e}function Ga(a){if(z(a))return null;a=a.previousSibling;for(var b=0;a&&!z(a);)a&&3===a.nodeType&&(b+=a.data.length),a=a.previousSibling;return{type:"OFFSET_CHARACTER",assertion:null,characterOffset:b}}function Ha(a,b){return{type:"LOCAL_PATH",indirection:b,steps:L(a)}}
function L(a,b){for(var c=[],d=[];a.parentNode&&!y(a.parentNode)&&a!==b;)c.push(a),a=a.parentNode;for(a=c.length-1;0<=a;a--){b=c[a];var e=null;b.id&&(e={type:"ASSERTION_XML_ID",xmlId:b.id,parameters:[]});d.push({type:"STEP_ELEMENT",stepValue:2*(Ia(b)+1),assertion:e})}return d}function Fa(a){for(a=a.previousSibling;a&&!z(a);)a=a.previousSibling;return{type:"STEP_TEXT",stepValue:a?2*Ia(a)+3:1,assertion:null}}function Ia(a){for(var b=0;a=a.previousElementSibling;)b++;return b};function M(a){this.b=a?a:za()}l=M.prototype;l.appendLocalPathTo=function(a){if(this.b.rangeStartPath||this.b.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");a=L(a);this.b.parentPath||(this.b.parentPath=J());this.b.parentPath.localPaths.push({type:"LOCAL_PATH",steps:a,indirection:0<this.b.parentPath.localPaths.length})};
l.appendTerminalDomPosition=function(a,b){b=Math.round(b);if(this.b.rangeStartPath||this.b.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");this.b.parentPath||(this.b.parentPath=J());Ea(a,b,this.b.parentPath)};
l.appendTerminalDomRange=function(a){var b=this.b;b||(b=za());if(a.collapsed)b.parentPath||(b.parentPath=J()),Ea(a.startContainer,a.startOffset,b.parentPath);else{b.parentPath||(b.parentPath=J());if(b.rangeStartPath||b.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");b.rangeStartPath=J();b.rangeEndPath=J();Ea(a.startContainer,a.startOffset,b.rangeStartPath,a.commonAncestorContainer);Ea(a.endContainer,a.endOffset,b.rangeEndPath,a.commonAncestorContainer);var c=0<b.parentPath.localPaths.length;if(z(a.commonAncestorContainer))b.parentPath.localPaths.push(Ha(a.commonAncestorContainer,
c));else if(z(a.commonAncestorContainer.parentNode))c=Ha(a.commonAncestorContainer.parentNode,c),c.steps.push(Fa(a.commonAncestorContainer)),b.parentPath.localPaths.push(c);else throw new C("CONTAINER_NOT_ATTACHED_TO_DOCUMENT",""+a.commonAncestorContainer);}};
l.appendTerminalIndirection=function(){if(this.b.rangeStartPath||this.b.rangeEndPath)throw new C("RANGE_PATHS_ALREADY_SET");this.b.parentPath||(this.b.parentPath=J());this.b.parentPath.localPaths.push({type:"LOCAL_PATH",indirection:!0,steps:[{type:"STEP_ELEMENT",assertion:null,stepValue:0}]})};l.clone=function(){var a=pa(this.b,{},!1);return new M(a)};l.collapseToEnd=function(){this.b.rangeEndPath&&Ja(this,this.b.rangeEndPath)};l.collapseToStart=function(){this.b.rangeStartPath&&Ja(this,this.b.rangeStartPath)};
l.getEpubCfiRootNode=function(){return this.b};l.prependLocalPathTo=function(a){a=L(a);this.b.parentPath||(this.b.parentPath=J());var b=this.b.parentPath.localPaths[0];b&&(b.indirection=!0);this.b.parentPath.localPaths.unshift({type:"LOCAL_PATH",steps:a,indirection:!1})};l.toString=function(){return Ba(this.b)};
function Ja(a,b){a.b.rangeStartPath=null;a.b.rangeEndPath=null;a.b.parentPath||(a.b.parentPath=J());a=a.b.parentPath;if(a.localPaths){var c=b.localPaths;c=0<c.length?c[0]:void 0;var d=x(a.localPaths);c&&(d&&!c.indirection&&(d.steps.push.apply(d.steps,u(c.steps)),b.localPaths.shift()),a.localPaths.push.apply(a.localPaths,u(b.localPaths)))}else a.localPaths=b.localPaths;a.offset=b.offset};function N(a){var b=w.FragmentSelector.call(this)||this;b.b=a;b.s="";return b}ka(N,w.FragmentSelector);l=N.prototype;l.collapseToStart=function(){if(!this.isRangeSelector())return this;var a=new M(H(this.b));a.collapseToStart();return new N(a.getEpubCfiRootNode())};l.collapseToEnd=function(){if(!this.isRangeSelector())return this;var a=new M(H(this.b));a.collapseToEnd();return new N(a.getEpubCfiRootNode())};
l.createRangeSelectorTo=function(a){if(!(a instanceof N))return null;var b=this.b.parentPath;this.b.rangeStartPath&&(b=new M(H(this.b)),b.collapseToStart(),b=b.getEpubCfiRootNode().parentPath);var c=a.b.parentPath;a.b.rangeEndPath&&(c=new M(H(a.b)),c.collapseToEnd(),c=c.getEpubCfiRootNode().parentPath);if(!c||!b)return null;a=za();for(var d=J(),e=J(),g=J(),f=Math.min(b.localPaths.length,c.localPaths.length),h=!1,k=0;!h&&k<f;){var m=b.localPaths[k],n=c.localPaths[k];if(m.indirection!==n.indirection)h=
!0;else{var p=m.steps;n=n.steps;for(var q=Math.min(p.length,n.length),r=0;!h&&r<q;){var v=p[r];var t=n[r];v.stepValue!==t.stepValue||v.assertion&&!t.assertion||!v.assertion&&t.assertion?t=!1:(v=v.assertion,t=t.assertion,t=v&&t?Ka(v,t):!0);t?r++:h=!0}p.length!==n.length&&(h=!0);if(h){if(0<r){q=ya();q.indirection=m.indirection;for(m=0;m<r;m++)q.steps.push(F(p[m]));d.localPaths.push(q);if(r<p.length){m=ya();for(q=r;q<p.length;q++)m.steps.push(F(p[q]));e.localPaths.push(m)}if(r<n.length){for(p=ya();r<
n.length;r++)p.steps.push(F(n[r]));g.localPaths.push(p)}k++}}else d.localPaths.push(E(m)),k++}}b.localPaths.length!==c.localPaths.length&&(h=!0);if(h){for(f=k;f<b.localPaths.length;f++)e.localPaths.push(E(b.localPaths[f]));for(;k<c.localPaths.length;k++)g.localPaths.push(E(c.localPaths[k]));b.offset&&(e.offset=G(b.offset));c.offset&&(g.offset=G(c.offset))}else b=b.offset,c=c.offset,b&&!c||!b&&c||b&&c&&!La(b,c)?(e.offset=b?G(b):null,g.offset=c?G(c):null,h=!0):d.offset=b?G(b):null;a.parentPath=d;h&&
(a.rangeStartPath=e,a.rangeEndPath=g);return new N(a)};l.getFragmentSpecificationIri=function(){return w.FragmentSpecificationIri.EPUB3};l.getRefinementSelectors=function(){return[]};l.getRootNode=function(){return this.b};l.getValue=function(){this.s||(this.s=Ba(this.b));return this.s};l.isRangeSelector=function(){return!!this.b.rangeEndPath};
function Ka(a,b){if(a.type!==b.type||a.parameters.length!==b.parameters.length||!a.parameters.every(function(c,d){var e=b.parameters[d];return c.name===e.name&&c.values.length===e.values.length&&c.values.every(function(g,f){return g===e.values[f]})}))return!1;switch(a.type){case "ASSERTION_TEXT_LOCATION":if(a.textAfter!==b.textAfter||a.textBefore!==b.textBefore)return!1;break;case "ASSERTION_XML_ID":if(a.xmlId!==b.xmlId)return!1}return!0}
function La(a,b){if(a.type!==b.type)return!1;switch(a.type){case "OFFSET_CHARACTER":if(a.characterOffset!==b.characterOffset)return!1;break;case "OFFSET_SPATIAL":if(a.x!==b.x||a.y!==b.y)return!1;break;case "OFFSET_TEMPORAL":if(a.seconds!==b.seconds||a.x!==b.x||a.y!==b.y)return!1}if(a.assertion&&!b.assertion||!a.assertion&&b.assertion)return!1;a=a.assertion;b=b.assertion;return a&&b?Ka(a,b):!0};function O(a){this.a=a;this.c=0}O.prototype.next=function(){return this.a[this.c++]};O.prototype.peek=function(){return this.a[this.c]};function Ma(a){this.f=!1;this.c=void 0;this.src="string"===typeof a?a.replace(/[^\x09\x0A\x0D\u0020-\uFFFD]/g,""):"";var b=this.src.indexOf("epubcfi(");0<b&&(this.src=this.src.substring(b));-1===b?(this.a=0,this.f=!0,this.c={type:-1,value:this.src,srcOffset:0}):(this.a=8,this.c={type:0,value:"epubcfi(",srcOffset:0});this.srcModified=this.src!==a}Ma.prototype.next=function(){if(void 0!==this.c){var a=this.c;this.c=void 0;return a}return Na(this)};
Ma.prototype.peek=function(){void 0===this.c&&(this.c=Na(this));return this.c};
function Na(a){if(!a.f){var b=a.a++,c=a.src.charCodeAt(b);if(33===c)b=P(40,"!",b);else if(44===c)b=P(41,",",b);else if(47===c){for(var d=c=a.a;Q(a.src.charCodeAt(c));)c++;a.a=c;b=d===c?P(-1,"/",b):{type:11,value:Number(a.src.substring(d,c)),srcOffset:d}}else if(58===c)b=P(42,":",b);else if(64===c)b=P(45,"@",b);else if(126===c)b=P(46,"~",b);else if(91===c){c=!0;for(d=[];c;){var e=void 0,g=a.a++,f=a.src.charCodeAt(g);if(44===f)e=P(41,",",g);else if(59===f)e=P(43,";",g);else if(61===f)e=P(44,"=",g);
else if(93===f)c=!1;else if(isNaN(f))e=P(-2,"",g),c=!1;else if(91===f||40===f||41===f)e=P(-1,String.fromCharCode(f),g);else{e=a;for(var h=f=g,k=!1,m="",n=!0;n;){var p=e.src.charCodeAt(f);94===p?(p=e.src.charCodeAt(f+1),isNaN(p)?n=!1:(m+=e.src.substring(h,f),55296<=p&&56319>=p?(m+=String.fromCharCode(p,e.src.charCodeAt(f+2)),f+=3):(m+=String.fromCharCode(p),f+=2),h=f)):91===p||93===p||40===p||41===p||44===p||59===p||61===p||isNaN(p)?n=!1:(32===p&&(k=!0),f++)}e.a=f;e={type:30,value:m+e.src.substring(h,
f),srcOffset:g,w:k}}e&&d.push(e)}b={type:20,value:d,srcOffset:b}}else if(41===c)b=P(1,")",b),a.f=!0;else if(isNaN(c))b=P(-2,"",b),a.f=!0;else if(Q(c)){for(c=a.a;Q(d=a.src.charCodeAt(c));)c++;if(46===d&&Q(a.src.charCodeAt(c+1)))for(c+=2;Q(a.src.charCodeAt(c));)c++;a.a=c;b={type:10,value:Number(a.src.substring(b,c)),srcOffset:b}}else{for(c=a.a;Oa(a.src.charCodeAt(c));)c++;a.a=c;b=P(-1,a.src.substring(b,c),b)}return b}}function P(a,b,c){return{type:a,value:b,srcOffset:c}}
function Oa(a){return 128<a||97<=a&&122>=a||65<=a&&97>=a}function Q(a){return 48<=a&&57>=a};function S(a,b){var c;if(ra(b)){var d=b.srcOffset;"string"===typeof b.value?c=b.value:c=qa(b.value)?"["+b.value.map(function(e){return e.value}).join("")+"]":""+b.value}else d=b;return{type:a,srcOffset:d,value:c||""}};function T(a){this.errors=[];this.a=new Ma(a)}T.prototype.parse=function(){var a=null,b=null,c=null;if(U(this,0,"INVALID_EPUBCFI_START")){a=Pa(this);var d=this.a.peek();d&&41===d.type&&(this.a.next(),b=Pa(this),U(this,41,"MISSING_END_RANGE")&&(c=Pa(this)));U(this,1,"INVALID_EPUBCFI_END")}return{type:"ROOT",srcOffset:0,src:this.a.src,srcModified:this.a.srcModified,parentPath:a,rangeStartPath:b,rangeEndPath:c,errors:this.errors}};
function U(a,b,c,d,e){e=e||a.a;var g=e.peek();if(g&&g.type===b)return e.next();V(a,c,g||{type:-2,value:"",srcOffset:d?d.srcOffset:a.a.a})}function Qa(a,b){var c=Ra(a,new O(b.value));return 0===c.length?(V(a,"INVALID_ASSERTION",b),null):Sa(c,b.srcOffset)}
function Ta(a,b){var c=b.next();if(!c)return null;if(43!==c.type)return V(a,"INVALID_PARAMETER",c),null;var d=U(a,30,"INVALID_PARAMETER",c,b);if(!d)return null;if(d.w)return V(a,"INVALID_PARAMETER",d),null;if(!U(a,44,"INVALID_PARAMETER",d,b))return null;var e=U(a,30,"INVALID_PARAMETER",c,b);if(!e)return null;for(var g=[e.value],f;(f=b.peek())&&43!==f.type;)b.next(),41!==f.type?V(a,"INVALID_PARAMETER",f):(e=U(a,30,"INVALID_PARAMETER",f,b))&&g.push(e.value);return{type:"PARAMETER",srcOffset:c.srcOffset,
name:d.value,values:g}}function Ra(a,b){for(var c=[];b.peek();){var d=Ta(a,b);d&&c.push(d)}return c}
function Pa(a){var b=[],c=a.a.a,d=null,e=a.a.peek();if(e&&(11===e.type||40===e.type)){b=!1;var g;e=[];for(var f=!1;!b&&(g=a.a.peek());)if(11===g.type){for(var h=void 0,k=a,m=[];(h=k.a.peek())&&11===h.type;){var n=k,p=k.a.next(),q=0===p.value%2?"STEP_ELEMENT":"STEP_TEXT",r=p?p.srcOffset:n.a.a,v=null,t=n.a.peek();if(t&&20===t.type)if("STEP_TEXT"===q)v=Qa(n,n.a.next());else{v=void 0;t=n;n=n.a.next();var I=new O(n.value),ba=null,va=I.peek();va&&30===va.type&&(ba=va.value,I.next());I=Ra(t,I);null===ba&&
0===I.length&&V(t,"INVALID_ASSERTION",n);ba?v={type:"ASSERTION_XML_ID",xmlId:ba,srcOffset:n.srcOffset,parameters:I}:v=Sa(I,n.srcOffset)}m.push({type:q,srcOffset:r,stepValue:p.value,assertion:v})}e.push({type:"LOCAL_PATH",steps:m,indirection:f,srcOffset:g.srcOffset});f=!1}else 40===g.type?(f&&V(a,"INVALID_INDIRECTION",g),f=!0,a.a.next()):b=!0;f&&g&&e.push({type:"LOCAL_PATH",steps:[],indirection:!0,srcOffset:g.srcOffset});b=e;e=a.a.peek()}if(g=e)g=e.type,g=46===g||45===g||42===g;if(g){d=null;if(g=a.a.peek())if(46===
g.type){var R=a.a.next();if(d=U(a,10,"INVALID_TEMPORAL_OFFSET",R)){g=a.a.peek();f=e=null;if(g&&45===g.type){if(e=Ua(a,a.a.next()))f=e.assertion;g=a.a.peek()}!f&&g&&20===g.type&&(f=Qa(a,a.a.next()));d={type:"OFFSET_TEMPORAL",srcOffset:R.srcOffset,assertion:f,seconds:d.value,x:e?e.x:null,y:e?e.y:null}}else d=null}else 45===g.type?d=Ua(a,a.a.next()):42===g.type&&(d=a.a.next(),(g=U(a,10,"INVALID_CHARACTER_OFFSET",d))?(e=null,(f=a.a.peek())&&20===f.type&&(e=a.a.next(),k=new O(e.value),h=f=null,(m=k.peek())&&
30===m.type&&(f=m,k.next(),m=k.peek()),m&&41===m.type&&(k.next(),h=U(a,30,"INVALID_ASSERTION",m,k)),k=Ra(a,k),null===f&&null===h&&0===k.length?(V(a,"INVALID_ASSERTION",e),e=null):(f||h?R={type:"ASSERTION_TEXT_LOCATION",textBefore:f?f.value:null,textAfter:h?h.value:null,parameters:k,srcOffset:e.srcOffset}:R=Sa(k,e.srcOffset),e=R)),d={type:"OFFSET_CHARACTER",srcOffset:d.srcOffset,characterOffset:g.value,assertion:e}):d=null);e=a.a.peek()}return{type:"PATH",offset:d,localPaths:b,srcOffset:c,complete:!!e&&
(41===e.type||1===e.type)}}
function Ua(a,b){var c=U(a,10,"INVALID_SPATIAL_OFFSET",b);if(!c)return null;var d=c.value;if(0>d||100<d)V(a,"INVALID_SPATIAL_OFFSET_VALUE",c),d=0>d?0:100<d?100:d;c=U(a,42,"INVALID_SPATIAL_OFFSET",c);if(!c)return null;var e=U(a,10,"INVALID_SPATIAL_OFFSET",c);if(!e)return null;c=e.value;if(0>c||100<c)V(a,"INVALID_SPATIAL_OFFSET_VALUE",e),c=0>c?0:100<c?100:c;e=a.a.peek();var g=null;e&&20===e.type&&(g=Qa(a,a.a.next()));return{type:"OFFSET_SPATIAL",srcOffset:b.srcOffset,assertion:g,x:d,y:c}}
function V(a,b,c){a.errors.push(S(b,c))}T.isEpubCfi=Va;T.parse=Wa;function Sa(a,b){return{type:"ASSERTION_GENERIC",parameters:a,srcOffset:b}}function Va(a){return 0<=a.indexOf("epubcfi(")}function Wa(a){return(new T(a)).parse()};function Xa(){}Xa.prototype.canParseFragment=function(a){return a.startsWith("epubcfi(")};Xa.prototype.getFragmentSpecificationIri=function(){return w.FragmentSpecificationIri.EPUB3};Xa.prototype.parseFragment=function(a){a=Wa(a);return a.parentPath?new N(a):null};function W(a,b,c,d,e,g){this.repairedWithXmlIdAssertions=this.missingXmlIdAssertions=!1;this.resolverErrors=[];this.stepsResolved=!0;this.ast=a;this.documentUrl=b;this.container=c;this.offset=d;this.isOpfDocument="package"===this.getDocument().documentElement.nodeName;this.intendedTargetType=e;this.virtualTarget=g}W.prototype.createResolverError=function(a,b,c){this.resolverErrors.push({type:a,documentUrl:this.documentUrl,node:b,errorData:c})};
W.prototype.getDocument=function(){return y(this.container)?this.container:this.container.ownerDocument};W.prototype.getTargetElement=function(){if("ELEMENT"===this.intendedTargetType&&null===this.virtualTarget&&(z(this.container)||y(this.container))){var a=this.container.childNodes[this.offset];return z(a)?a:null}return null};W.prototype.getTargetNode=function(){var a=null;null===this.virtualTarget&&(a=z(this.container)||y(this.container)?this.container.childNodes[this.offset]:this.container);return a};function Ya(a,b,c){this.f=a;this.a=b;this.c=c}
Ya.prototype.resolve=function(){for(var a=this.f.steps,b=0;b<a.length;b++){var c=a[b];if("STEP_ELEMENT"===c.type)if(this.a.intendedTargetType="ELEMENT",this.a.virtualTarget)Za(this,c)&&(this.a.stepsResolved=!0,this.a.virtualTarget=null);else{if(z(this.c)){var d=this.c,e=c,g=e.stepValue/2-1,f=d.children,h=f.length;0<=g&&g<h?this.c=f[g]:0>g?this.a.virtualTarget="FIRST_CHILD":(this.a.virtualTarget="LAST_CHILD",g!==h&&(this.a.createResolverError("STEP_VALUE_OUT_OF_BOUNDS",d,e),this.a.stepsResolved=!1));
Za(this,c)}}else if(e=c,this.a.intendedTargetType="TEXT",!this.a.virtualTarget&&z(this.c))if(c=void 0,d=this.c,g=e,e=(g.stepValue>>1)-1,e<d.childElementCount)if(g=-1===e?0:B(d.children[e])+1,g<d.childNodes.length){for(e=d=d.childNodes[g];!c&&e&&!z(e);)e&&3===e.nodeType&&(c=e),e=e.nextSibling;this.c=c||d}else this.a.virtualTarget=-1===e?"FIRST_CHILD":"LAST_CHILD";else this.a.virtualTarget="LAST_CHILD",this.a.createResolverError("STEP_VALUE_OUT_OF_BOUNDS",d,g),this.a.stepsResolved=!1}this.a.virtualTarget?
(this.a.container=this.c,this.a.offset="FIRST_CHILD"===this.a.virtualTarget?0:this.c.childNodes.length):(this.a.container=this.c.parentNode,this.a.offset=B(this.c));return this.a};
function Za(a,b){var c=!1,d=a.c;if((b=b.assertion)&&b.xmlId){if(null!==a.a.virtualTarget||!z(d)||d.id!==b.xmlId){var e=d.ownerDocument.getElementById(b.xmlId);!e||"itemref"===d.nodeName&&"itemref"!==e.nodeName?a.a.createResolverError("XML_ID_ASSERTION_FAILED",d,b):(a.c=e,a.a.repairedWithXmlIdAssertions=!0,a.a.virtualTarget=null,c=!0)}}else null===a.a.virtualTarget&&z(d)&&d.id&&(a.a.missingXmlIdAssertions=!0);return c};function $a(a,b){this.characterOffsetOutOfBounds=!1;this.elementCharacterOffset=null;this.indirectionsResolved=!0;this.spatialOffset=this.sideBias=null;this.stepsResolved=!0;this.temporalOffset=null;this.ast=a;this.localPaths=[];this.addResolvedLocalPath(b)}l=$a.prototype;
l.addResolvedLocalPath=function(a){this.localPaths.push(a);this.stepsResolved=a.stepsResolved;this.container=a.container;this.documentUrl=a.documentUrl;this.intendedTargetType=a.intendedTargetType;this.offset=a.offset;this.virtualTarget=a.virtualTarget};l.getDocument=function(){return y(this.container)?this.container:this.container.ownerDocument};l.getResolverErrors=function(){for(var a=[],b=0;b<this.localPaths.length;b++){var c=this.localPaths[b];0<c.resolverErrors.length&&a.push.apply(a,u(c.resolverErrors))}return a};
l.getTargetElement=function(){return x(this.localPaths).getTargetElement()};l.getTargetNode=function(){return x(this.localPaths).getTargetNode()};l.getTerminalLocalPath=function(){return x(this.localPaths)};l.isMissingXmlIdAssertions=function(){return this.localPaths.some(function(a){return a.missingXmlIdAssertions})};l.isRepairedWithXmlIdAssertions=function(){return this.localPaths.some(function(a){return a.repairedWithXmlIdAssertions})};l.isTargetingOpfDocument=function(){return x(this.localPaths).isOpfDocument};function ab(a){this.indirectionErrors=[];this.rangeStartPath=this.rangeEndPath=this.parentPath=null;this.ast=a}l=ab.prototype;
l.createDomRange=function(){if(this.isTargetingOpfDocument())return null;var a=null;if(this.rangeStartPath&&this.rangeEndPath){var b=this.rangeStartPath.getDocument();a=b.createRange();a.setStart(this.rangeStartPath.container,this.rangeStartPath.offset);this.rangeEndPath.getDocument()===b&&a.setEnd(this.rangeEndPath.container,this.rangeEndPath.offset)}else this.parentPath&&(a=this.parentPath.getDocument().createRange(),a.setStart(this.parentPath.container,this.parentPath.offset));return a};
l.getElementCharacterOffsets=function(){return bb(this,"elementCharacterOffset",{characterOffset:0})};l.getParserErrors=function(){return this.ast.errors};l.getResolverErrors=function(){for(var a=[],b=[this.parentPath,this.rangeStartPath,this.rangeEndPath],c=0;c<b.length;c++){var d=b[c];d&&a.push.apply(a,u(d.getResolverErrors()))}return a};l.getSideBias=function(){return!this.parentPath||this.rangeStartPath||this.rangeEndPath?null:this.parentPath.sideBias};
l.getSpatialOffsets=function(){return bb(this,"spatialOffset",{x:0,y:0})};l.getTargetElement=function(){if(this.rangeStartPath&&this.rangeEndPath){var a=this.rangeStartPath.getTargetElement();return null!==a&&a===this.rangeEndPath.getTargetElement()?a:null}return this.parentPath?this.parentPath.getTargetElement():null};l.getTemporalOffsets=function(){return bb(this,"temporalOffset",{seconds:0})};
l.hasElementOffsets=function(){return null!==this.getSpatialOffsets()||null!==this.getTemporalOffsets()||null!==this.getElementCharacterOffsets()};l.hasErrors=function(){return!this.hasParentPathOrRangePaths()||this.isTargetingOpfDocument()||!this.isEveryIndirectionResolved()||0<this.ast.errors.length||!this.isEveryStepAndOffsetParsed()||!this.isEveryStepResolved()||this.isSomeCharacterOffsetOutOfBounds()};
l.hasParentPathOrRangePaths=function(){return null!==this.parentPath&&(null===this.rangeStartPath&&null===this.rangeStartPath||null!==this.rangeStartPath&&null!==this.rangeEndPath)};l.hasRangePaths=function(){return null!==this.rangeStartPath&&null!==this.rangeEndPath};l.hasWarnings=function(){return this.isMissingXmlIdAssertions()||this.isRepairedWithXmlIdAssertions()};
l.isDomRange=function(){var a=this.rangeStartPath,b=this.rangeEndPath;return!!a&&!!b&&!this.isTargetingOpfDocument()&&a.getDocument()===b.getDocument()&&(a.container!==b.container||a.offset!==b.offset)};l.isEveryIndirectionResolved=function(){return 0===this.indirectionErrors.length};l.isEveryStepAndOffsetParsed=function(){return null!==this.ast.parentPath&&this.ast.parentPath.complete&&(!this.ast.rangeStartPath||this.ast.rangeStartPath.complete)&&(!this.ast.rangeEndPath||this.ast.rangeEndPath.complete)};
l.isEveryStepResolved=function(){return null!==this.parentPath&&this.parentPath.stepsResolved&&(!this.rangeStartPath||this.rangeStartPath.stepsResolved)&&(!this.rangeEndPath||this.rangeEndPath.stepsResolved)};l.isMissingXmlIdAssertions=function(){return null!==this.parentPath&&this.parentPath.isMissingXmlIdAssertions()||null!==this.rangeStartPath&&this.rangeStartPath.isMissingXmlIdAssertions()||null!==this.rangeEndPath&&this.rangeEndPath.isMissingXmlIdAssertions()};
l.isOwnedBySingleDocument=function(){return this.rangeStartPath&&this.rangeEndPath?this.rangeStartPath.getDocument()===this.rangeEndPath.getDocument():!0};l.isRepairedWithXmlIdAssertions=function(){return null!==this.parentPath&&this.parentPath.isRepairedWithXmlIdAssertions()||null!==this.rangeStartPath&&this.rangeStartPath.isRepairedWithXmlIdAssertions()||null!==this.rangeEndPath&&this.rangeEndPath.isRepairedWithXmlIdAssertions()};
l.isSomeCharacterOffsetOutOfBounds=function(){return[this.parentPath,this.rangeStartPath,this.rangeEndPath].some(function(a){return null!==a&&a.characterOffsetOutOfBounds})};l.isTargetingElement=function(){return null!==this.getTargetElement()};l.isTargetingOpfDocument=function(){return this.rangeStartPath&&this.rangeEndPath?this.rangeStartPath.isTargetingOpfDocument()||this.rangeEndPath.isTargetingOpfDocument():null!==this.parentPath&&this.parentPath.isTargetingOpfDocument()};
function bb(a,b,c){var d=null,e=a.rangeStartPath,g=a.rangeEndPath;e&&g?g[b]?d={start:e[b]||c,end:g[b]}:e[b]&&(d={start:e[b],end:null}):(a=a.parentPath)&&a[b]&&(d={start:a[b],end:null});return d};function cb(a){var b=a.ast.offset;if("ELEMENT"===a.intendedTargetType&&b&&a.stepsResolved)if("OFFSET_TEMPORAL"===b.type)a.temporalOffset={seconds:b.seconds},A(b.x)&&A(b.y)&&(a.spatialOffset={x:b.x,y:b.y});else if("OFFSET_SPATIAL"===b.type)a.spatialOffset={x:b.x,y:b.y};else{if("OFFSET_CHARACTER"===b.type){var c=a.getTargetElement();c&&"img"===c.localName&&(a.elementCharacterOffset=b.characterOffset)}}else"TEXT"===a.intendedTargetType&&(c=!1,b&&("OFFSET_CHARACTER"===b.type&&a.stepsResolved&&(db(a,b.characterOffset),
c=!0),b.assertion&&(c=!0)),c||db(a,0))}function db(a,b){for(var c=a.getTargetNode(),d=[];c&&!z(c);)c&&3===c.nodeType&&d.push(c),c=c.nextSibling;0<d.length?(c=d.find(function(e){if(b<e.length)return!0;b-=e.length;return!1}))?(a.container=c,a.offset=b):(d=d[d.length-1],a.container=d,a.offset=d.data.length,a.characterOffsetOutOfBounds=0!==b):0<b&&(a.characterOffsetOutOfBounds=!0)};function X(a){this.h=0;this.o=!1;this.j=this.i=0;this.l=!1;this.b=a="string"===typeof a?Wa(a):a;this.g=a.parentPath;this.f=a.rangeStartPath;this.c=a.rangeEndPath;this.a=new ab(a)}
X.prototype.continueResolving=function(a,b){var c,d=null;y(a)?d=a.documentElement:a&&11===a.nodeType&&z(a.firstChild)?d=a.firstChild:z(a)&&a.parentNode&&(d=a);if(!d)return this.a.indirectionErrors.push({from:null,fromPath:(null===(c=eb(this))||void 0===c?void 0:c.documentUrl)||null,target:d,targetPath:b}),null;if(this.g)a:{for(a=fb(this,1,d,b);this.g;){if(Y(this,1)){this.a.parentPath&&(this.a.parentPath.indirectionsResolved=!1);break a}gb(this,1,a,b)}hb(this,a,b);ib(this,a,b)}else this.f?(a=fb(this,
2,d,b),hb(this,a,b)):this.c&&(a=fb(this,3,d,b),ib(this,a,b));b=eb(this);jb(this);return b};X.prototype.getResolvedTarget=function(){return this.a};X.prototype.skipNextIndirection=function(){kb(this);var a=eb(this);jb(this);return a};
X.prototype.addResolvedLocalPath=function(a,b){a:{switch(a){case 1:var c=this.a.parentPath;break a;case 3:c=this.a.rangeEndPath;break a;case 2:c=this.a.rangeStartPath;break a}c=void 0}if(c)c.addResolvedLocalPath(b),c.indirectionsResolved=!0;else switch(a){case 1:c=this.a.parentPath=new $a(this.b.parentPath,b);break;case 2:c=this.a.rangeStartPath=new $a(this.b.rangeStartPath,b);break;case 3:c=this.a.rangeEndPath=new $a(this.b.rangeEndPath,b)}return c};
function lb(a,b){var c=null;switch(b){case 1:a.g&&(c=Z(a.g,a.h++),Z(a.g,a.h)||(a.g=null));break;case 2:a.f&&(c=Z(a.f,a.j++),Z(a.f,a.j)||(a.f=null));break;case 3:a.c&&(c=Z(a.c,a.i++),Z(a.c,a.i)||(a.c=null))}return c}function ib(a,b,c){for(;a.c;){if(Y(a,3)){a.a.rangeEndPath&&(a.a.rangeEndPath.indirectionsResolved=!1);break}b=gb(a,3,b,c)}}function hb(a,b,c){for(;a.f;){if(Y(a,2)){a.a.rangeStartPath&&(a.a.rangeStartPath.indirectionsResolved=!1);break}b=gb(a,2,b,c)}}
function Z(a,b){return b<a.localPaths.length?a.localPaths[b]:null}function eb(a){var b=null,c=null;Y(a,1)?b=a.a.parentPath:Y(a,2)?b=a.a.rangeStartPath||a.a.parentPath:Y(a,3)&&(b=a.a.rangeEndPath||a.a.parentPath);b&&((c=b.getTargetElement())?c={documentUrl:b.documentUrl,element:c}:(a.a.indirectionErrors.push({from:b.getTargetNode(),fromPath:b.documentUrl,target:null,targetPath:null}),kb(a),c=eb(a)));return c}
function mb(a){var b=null;if(a.ast.offset&&a.ast.offset.assertion)b=a.ast.offset.assertion;else{var c=a.getTerminalLocalPath();c.ast&&(c=x(c.ast.steps))&&(b=c.assertion)}b&&(b=b.parameters.find(function(d){return"s"===d.name&&0<d.values.length}))&&(a.sideBias="b"===b.values[0]?"BEFORE":"AFTER")}
function kb(a){a.g?(a.g=null,a.f=null,a.c=null,a.a.parentPath&&(a.a.parentPath.indirectionsResolved=!1,a.a.parentPath.stepsResolved=!1)):a.f?(a.f=null,a.a.rangeStartPath&&(a.a.rangeStartPath.indirectionsResolved=!1,a.a.rangeStartPath.stepsResolved=!1)):a.c&&(a.c=null,a.a.rangeEndPath&&(a.a.rangeEndPath.indirectionsResolved=!1,a.a.rangeEndPath.stepsResolved=!1))}
function Y(a,b){var c=!1;switch(b){case 1:a.g&&(a=Z(a.g,a.h),c=null!==a&&a.indirection);break;case 2:a.f&&(a=Z(a.f,a.j),c=null!==a&&a.indirection);break;case 3:a.c&&(a=Z(a.c,a.i),c=null!==a&&a.indirection)}return c}function jb(a){!a.a.parentPath||a.g||a.o||(cb(a.a.parentPath),mb(a.a.parentPath),a.o=!0);!a.a.rangeStartPath||a.f||a.l||(cb(a.a.rangeStartPath),mb(a.a.rangeStartPath),a.l=!0);a.a.rangeEndPath&&!a.c&&(cb(a.a.rangeEndPath),mb(a.a.rangeEndPath),a.l=!0)}
function gb(a,b,c,d){var e=lb(a,b);e?(c=new W(e,c.documentUrl,c.container,c.offset,c.intendedTargetType,c.virtualTarget),e=(new Ya(e,c,c.getTargetNode()||c.container)).resolve()):e=new W(null,d,c.container,c.offset,c.intendedTargetType,c.virtualTarget);return a.addResolvedLocalPath(b,e)}function fb(a,b,c,d){var e=lb(a,b);e?(d=new W(e,d,c.parentNode,B(c),"ELEMENT",null),c=(new Ya(e,d,c)).resolve()):c=new W(null,d,c.parentNode,B(c),"ELEMENT",null);return a.addResolvedLocalPath(b,c)};function nb(a){function b(d){var e=x(d.localPaths);e&&(e=x(e.steps))&&"STEP_TEXT"===e.type&&!d.offset&&(d.offset={type:"OFFSET_CHARACTER",characterOffset:0,assertion:null},c=!0)}var c=!1;a.parentPath&&b(a.parentPath);a.rangeStartPath&&b(a.rangeStartPath);a.rangeEndPath&&b(a.rangeEndPath);return c}
function ob(a,b){var c=!1;a.parentPath&&0!==a.parentPath.localPaths.length&&0!==a.parentPath.localPaths[0].steps.length?a.rangeStartPath&&!a.rangeEndPath?(b||a.errors.push(S("MISSING_END_RANGE")),c=!0,a.rangeStartPath=null):a.rangeEndPath&&(0===a.rangeEndPath.localPaths.length||0===a.rangeEndPath.localPaths[0].steps.length&&null===a.rangeEndPath.offset)&&(b||a.errors.push(S("PATH_EMPTY",a.rangeEndPath.srcOffset)),c=!0,a.rangeStartPath=null,a.rangeEndPath=null):(b||a.errors.push(S("PATH_EMPTY",a.srcOffset)),
c=!0,a.parentPath=null,a.rangeStartPath=null,a.rangeEndPath=null);return c}
function pb(a,b){function c(g){g.offset||(g=x(g.localPaths))&&g.indirection&&0===g.steps.length&&(b||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",g.srcOffset)),d=!0,g.steps.push({type:"STEP_ELEMENT",stepValue:0,assertion:null}))}var d=!1;if(a.parentPath&&!a.parentPath.offset){var e=x(a.parentPath.localPaths);e&&e.indirection&&0===e.steps.length&&(b||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",e.srcOffset)),d=!0,a.rangeStartPath&&a.rangeEndPath?(qb(a.rangeStartPath),qb(a.rangeEndPath),a.parentPath.localPaths.length--):
(e.steps.push({type:"STEP_ELEMENT",stepValue:0,assertion:null}),a.rangeStartPath=null,a.rangeEndPath=null))}a.rangeStartPath&&c(a.rangeStartPath);a.rangeEndPath&&c(a.rangeEndPath);return d}function rb(a,b){var c=!1;a.parentPath&&(c=sb(a,a.parentPath,b));a.rangeStartPath&&(c=sb(a,a.rangeStartPath,b)||c);a.rangeEndPath&&(c=sb(a,a.rangeEndPath,b)||c);return c}
function tb(a,b){var c=!1,d=a.rangeStartPath?a.rangeStartPath.offset:null,e=a.rangeEndPath?a.rangeEndPath.offset:null,g=!1;if(d&&e)d.type!==e.type?g=!0:"OFFSET_TEMPORAL"===d.type&&(null!==d.x&&null===e.x||null===d.x&&null!==e.x)&&(d.x=null,d.y=null,e.x=null,e.y=null,c=!0);else if(!d&&e&&"OFFSET_CHARACTER"!==e.type||d&&!e)g=!0;g&&(a.rangeStartPath&&(a.rangeStartPath.offset=null),a.rangeEndPath&&(a.rangeEndPath.offset=null),c=!0);c&&!b&&a.errors.push(S("INCOMPATIBLE_OFFSET_TYPE"));return c}
function ub(a,b){var c=!1;if(a.parentPath){var d=a.parentPath.localPaths[0];d&&d.indirection&&(d.indirection=!1,c=!0,b||a.errors.push(S("INVALID_INDIRECTION",d.srcOffset)))}return c}function vb(a,b){var c=!1;if(a.parentPath&&a.parentPath.offset){var d=a.parentPath.offset;d="OFFSET_TEMPORAL"===d.type&&null===d.x;a.rangeStartPath&&wb(a,a.rangeStartPath,d,b)&&(a.rangeStartPath=null,c=!0);a.rangeEndPath&&wb(a,a.rangeEndPath,d,b)&&(a.rangeEndPath=null,c=!0)}return c}
function xb(a,b){var c=!1;if(a.parentPath){var d=yb(a,a.parentPath,b);d.u?(c=!0,a.rangeStartPath=null,a.rangeEndPath=null):d.C&&(a.rangeStartPath&&0<a.rangeStartPath.localPaths.length&&(c=!0,a.rangeStartPath=null),a.rangeEndPath&&0<a.rangeEndPath.localPaths.length&&(c=!0,a.rangeEndPath=null))}a.rangeStartPath&&(d=yb(a,a.rangeStartPath,b),c=c||d.u);a.rangeEndPath&&(d=yb(a,a.rangeEndPath,b),c=c||d.u);return c}
function qb(a){if(a.localPaths[0])a.localPaths[0].indirection=!0;else{var b={type:"STEP_ELEMENT",stepValue:0,assertion:null};a.localPaths.push({type:"LOCAL_PATH",steps:a.offset?[]:[b],indirection:!0})}}
function yb(a,b,c){var d=!1,e=!1,g=b.localPaths.findIndex(function(f){var h=f.steps.findIndex(function(k){return"STEP_TEXT"===k.type});return-1!==h?(e=!0,h<f.steps.length-1&&(c||a.errors.push(S("INVALID_STEP",f.steps[h].srcOffset)),f.steps=f.steps.slice(0,h+1),b.offset=null,d=!0),!0):!1});-1!==g&&g<b.localPaths.length-1&&(b.localPaths=b.localPaths.slice(0,g+1),d=!0);return{u:d,C:e}}
function sb(a,b,c){var d=!1;if(b.offset){var e=x(b.localPaths);e&&e.indirection&&0===e.steps.length&&(c||a.errors.push(S("PATH_ENDS_WITH_INDIRECTION",e.srcOffset)),b.localPaths.length--,d=!0)}return d}function wb(a,b,c,d){var e=!1;0<b.localPaths.length?(d||a.errors.push(S("INVALID_RANGE_PATH",b.srcOffset)),e=!0):!b.offset||c&&"OFFSET_SPATIAL"===b.offset.type||(d||a.errors.push(S("INCOMPATIBLE_OFFSET_TYPE",b.offset.srcOffset)),e=!0);return e};api={EpubCfiBuilder:M,EpubCfiError:C,EpubCfiErrorType:{RANGE_PATHS_ALREADY_SET:"RANGE_PATHS_ALREADY_SET",CONTAINER_NOT_ATTACHED_TO_DOCUMENT:"CONTAINER_NOT_ATTACHED_TO_DOCUMENT"},EpubCfiIntendedTargetType:{ELEMENT:"ELEMENT",TEXT:"TEXT"},EpubCfiNodeType:{ROOT:"ROOT",PATH:"PATH",LOCAL_PATH:"LOCAL_PATH",STEP_ELEMENT:"STEP_ELEMENT",STEP_TEXT:"STEP_TEXT",ASSERTION_TEXT_LOCATION:"ASSERTION_TEXT_LOCATION",ASSERTION_XML_ID:"ASSERTION_XML_ID",ASSERTION_GENERIC:"ASSERTION_GENERIC",PARAMETER:"PARAMETER",OFFSET_CHARACTER:"OFFSET_CHARACTER",
OFFSET_TEMPORAL:"OFFSET_TEMPORAL",OFFSET_SPATIAL:"OFFSET_SPATIAL"},EpubCfiParser:T,EpubCfiParserErrorType:{INVALID_RANGE_PATH:"INVALID_RANGE_PATH",INCOMPATIBLE_OFFSET_TYPE:"INCOMPATIBLE_OFFSET_TYPE",INVALID_CHARACTER_OFFSET:"INVALID_CHARACTER_OFFSET",INVALID_ASSERTION:"INVALID_ASSERTION",INVALID_EPUBCFI_START:"INVALID_EPUBCFI_START",INVALID_EPUBCFI_END:"INVALID_EPUBCFI_END",INVALID_PARAMETER:"INVALID_PARAMETER",PATH_ENDS_WITH_INDIRECTION:"PATH_ENDS_WITH_INDIRECTION",INVALID_SPATIAL_OFFSET:"INVALID_SPATIAL_OFFSET",
INVALID_SPATIAL_OFFSET_VALUE:"INVALID_SPATIAL_OFFSET_VALUE",INVALID_STEP:"INVALID_STEP",INVALID_INDIRECTION:"INVALID_INDIRECTION",INVALID_TEMPORAL_OFFSET:"INVALID_TEMPORAL_OFFSET",MISSING_END_RANGE:"MISSING_END_RANGE",PATH_EMPTY:"PATH_EMPTY"},EpubCfiResolver:X,EpubCfiResolverErrorType:{XML_ID_ASSERTION_FAILED:"XML_ID_ASSERTION_FAILED",STEP_VALUE_OUT_OF_BOUNDS:"STEP_VALUE_OUT_OF_BOUNDS"},EpubCfiSelector:N,EpubCfiFragmentSelectorParser:Xa,EpubCfiSideBias:{BEFORE:"BEFORE",AFTER:"AFTER"},EpubCfiStringifier:{stringifyRootNode:Ba},
EpubCfiUtils:{copyRootNode:H},EpubCfiValidator:{addExplicitCharacterOffsets:nb,checkEmptyParentPathAndRangeEnd:ob,expandEmptyIndirectionLocalPaths:pb,removeEmptyIndirectionBeforeOffsets:rb,removeIncompatibleRangeOffsets:tb,removeInitialIndirectionFromParentPath:ub,removeInvalidRangeAfterParentPathOffset:vb,removeStepsAfterTextSteps:xb,runAllValidations:function(a,b){var c=!1;c=xb(a,b)||c;c=ub(a,b)||c;c=vb(a,b)||c;c=tb(a,b)||c;c=ob(a,b)||c;c=nb(a)||c;c=pb(a,b)||c;return c=rb(a,b)||c}},EpubCfiVirtualTarget:{FIRST_CHILD:"FIRST_CHILD",
LAST_CHILD:"LAST_CHILD"}};var zb=new ArrayBuffer(8);new DataView(zb);new Uint8Array(zb); }).call(__self); return api; }));
