/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base', './colibrio-core-io-mediatypedetector', './colibrio-core-url'], function (a0, a1, a2, a3) { return f(r, a0, a1, a2, a3); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base'), require('./colibrio-core-io-mediatypedetector'), require('./colibrio-core-url')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.io = r.colibrio.core.io || {}; r.colibrio.core.io.resourcecache = f(r, colibrio.core.base, colibrio.core.io.base, colibrio.core.io.mediatypedetector, colibrio.core.url); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_io_mediatypedetector,__dep__colibrio_core_url) { var api = {}; (function() { 'use strict';var m;function n(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function r(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:n(a)}}var aa="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},t;
if("function"==typeof Object.setPrototypeOf)t=Object.setPrototypeOf;else{var u;a:{var ba={I:!0},w={};try{w.__proto__=ba;u=w.I;break a}catch(a){}u=!1}t=u?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var x=t,y="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,z="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,f){a!=Array.prototype&&a!=Object.prototype&&(a[b]=f.value)};
function A(a,b){if(b){var f=y;a=a.split(".");for(var e=0;e<a.length-1;e++){var g=a[e];g in f||(f[g]={});f=f[g]}a=a[a.length-1];e=f[a];b=b(e);b!=e&&null!=b&&z(f,a,{configurable:!0,writable:!0,value:b})}}
A("Promise",function(a){function b(c){this.b=0;this.g=void 0;this.a=[];var h=this.c();try{c(h.resolve,h.reject)}catch(k){h.reject(k)}}function f(){this.a=null}function e(c){return c instanceof b?c:new b(function(h){h(c)})}if(a)return a;f.prototype.b=function(c){if(null==this.a){this.a=[];var h=this;this.c(function(){h.g()})}this.a.push(c)};var g=y.setTimeout;f.prototype.c=function(c){g(c,0)};f.prototype.g=function(){for(;this.a&&this.a.length;){var c=this.a;this.a=[];for(var h=0;h<c.length;++h){var k=
c[h];c[h]=null;try{k()}catch(l){this.f(l)}}}this.a=null};f.prototype.f=function(c){this.c(function(){throw c;})};b.prototype.c=function(){function c(l){return function(p){k||(k=!0,l.call(h,p))}}var h=this,k=!1;return{resolve:c(this.N),reject:c(this.f)}};b.prototype.N=function(c){if(c===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(c instanceof b)this.O(c);else{a:switch(typeof c){case "object":var h=null!=c;break a;case "function":h=!0;break a;default:h=!1}h?this.M(c):this.s(c)}};
b.prototype.M=function(c){var h=void 0;try{h=c.then}catch(k){this.f(k);return}"function"==typeof h?this.P(h,c):this.s(c)};b.prototype.f=function(c){this.D(2,c)};b.prototype.s=function(c){this.D(1,c)};b.prototype.D=function(c,h){if(0!=this.b)throw Error("Cannot settle("+c+", "+h+"): Promise already settled in state"+this.b);this.b=c;this.g=h;this.L()};b.prototype.L=function(){if(null!=this.a){for(var c=0;c<this.a.length;++c)d.b(this.a[c]);this.a=null}};var d=new f;b.prototype.O=function(c){var h=this.c();
c.G(h.resolve,h.reject)};b.prototype.P=function(c,h){var k=this.c();try{c.call(h,k.resolve,k.reject)}catch(l){k.reject(l)}};b.prototype.then=function(c,h){function k(q,v){return"function"==typeof q?function(K){try{l(q(K))}catch(L){p(L)}}:v}var l,p,M=new b(function(q,v){l=q;p=v});this.G(k(c,l),k(h,p));return M};b.prototype.catch=function(c){return this.then(void 0,c)};b.prototype.G=function(c,h){function k(){switch(l.b){case 1:c(l.g);break;case 2:h(l.g);break;default:throw Error("Unexpected state: "+
l.b);}}var l=this;null==this.a?d.b(k):this.a.push(k)};b.resolve=e;b.reject=function(c){return new b(function(h,k){k(c)})};b.race=function(c){return new b(function(h,k){for(var l=r(c),p=l.next();!p.done;p=l.next())e(p.value).G(h,k)})};b.all=function(c){var h=r(c),k=h.next();return k.done?e([]):new b(function(l,p){function M(K){return function(L){q[K]=L;v--;0==v&&l(q)}}var q=[],v=0;do q.push(void 0),v++,e(k.value).G(M(q.length-1),p),k=h.next();while(!k.done)})};return b});
A("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(f){return Promise.resolve(b()).then(function(){return f})},function(f){return Promise.resolve(b()).then(function(){throw f;})})}});function B(){B=function(){};y.Symbol||(y.Symbol=ca)}function da(a,b){this.a=a;z(this,"description",{configurable:!0,writable:!0,value:b})}da.prototype.toString=function(){return this.a};
var ca=function(){function a(f){if(this instanceof a)throw new TypeError("Symbol is not a constructor");return new da("jscomp_symbol_"+(f||"")+"_"+b++,f)}var b=0;return a}();function C(){B();var a=y.Symbol.iterator;a||(a=y.Symbol.iterator=y.Symbol("Symbol.iterator"));"function"!=typeof Array.prototype[a]&&z(Array.prototype,a,{configurable:!0,writable:!0,value:function(){return ea(n(this))}});C=function(){}}function ea(a){C();a={next:a};a[y.Symbol.iterator]=function(){return this};return a}
function D(){this.c=!1;this.a=null;this.s=void 0;this.f=1;this.D=0;this.b=null}function E(a){if(a.c)throw new TypeError("Generator is already running");a.c=!0}D.prototype.g=function(a){this.s=a};function F(a,b){a.b={J:b,K:!0};a.f=a.D}D.prototype.return=function(a){this.b={return:a};this.f=this.D};function fa(a,b){a.f=2;return{value:b}}function ha(a){this.a=new D;this.b=a}
function ia(a,b){E(a.a);var f=a.a.a;if(f)return G(a,"return"in f?f["return"]:function(e){return{value:e,done:!0}},b,a.a.return);a.a.return(b);return H(a)}function G(a,b,f,e){try{var g=b.call(a.a.a,f);if(!(g instanceof Object))throw new TypeError("Iterator result "+g+" is not an object");if(!g.done)return a.a.c=!1,g;var d=g.value}catch(c){return a.a.a=null,F(a.a,c),H(a)}a.a.a=null;e.call(a.a,d);return H(a)}
function H(a){for(;a.a.f;)try{var b=a.b(a.a);if(b)return a.a.c=!1,{value:b.value,done:!1}}catch(f){a.a.s=void 0,F(a.a,f)}a.a.c=!1;if(a.a.b){b=a.a.b;a.a.b=null;if(b.K)throw b.J;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function ja(a){this.next=function(b){E(a.a);a.a.a?b=G(a,a.a.a.next,b,a.a.g):(a.a.g(b),b=H(a));return b};this.throw=function(b){E(a.a);a.a.a?b=G(a,a.a.a["throw"],b,a.a.g):(F(a.a,b),b=H(a));return b};this.return=function(b){return ia(a,b)};C();this[Symbol.iterator]=function(){return this}}function ka(a){function b(e){return a.next(e)}function f(e){return a.throw(e)}return new Promise(function(e,g){function d(c){c.done?e(c.value):Promise.resolve(c.value).then(b,f).then(d,g)}d(a.next())})};var I=__dep__colibrio_core_base;var la=__dep__colibrio_core_io_base;var ma=__dep__colibrio_core_url;var na=new Uint16Array(1);na[0]=4660;new Uint8Array(na.buffer);function J(a,b){return I.ColibrioError.call(this,"colibrio.core.io.resourcecache",a,b)||this}var N=I.ColibrioError;J.prototype=aa(N.prototype);J.prototype.constructor=J;if(x)x(J,N);else for(var O in N)if("prototype"!=O)if(Object.defineProperties){var oa=Object.getOwnPropertyDescriptor(N,O);oa&&Object.defineProperty(J,O,oa)}else J[O]=N[O];J.a=N.prototype;function pa(a){this.a=a;this.f=[];this.b=!1;this.c=a.s++}m=pa.prototype;m.createUrlForContent=function(a,b,f){a=this.a.b.createUrl(a,b,f).url;this.f.push(a);return a};m.destroy=function(){var a=this;this.b||(this.b=!0,this.f.forEach(function(b){a.a.b.revokeUrl(b)}),this.f=[],qa(this.a,this.c))};m.fetchBlob=function(a,b){return this.b?P():ra(this.a,a,b,this.c)};m.fetchBrowserNativeUrl=function(a,b,f){return this.b?P():sa(this.a,a,b,f,this.c)};
m.fetchText=function(a,b){return this.b?P():ta(this.a,a,b,this.c)};m.fetchUint8Array=function(a,b){return this.b?P():ua(this.a,a,b,this.c)};m.isDestroyed=function(){return this.b};function P(){return Promise.reject(new J(I.ColibrioErrorType.ABORTED))};function va(a){return new Promise(function(b,f){var e=new FileReader;e.onload=function(){e.onabort=null;e.onload=null;e.onerror=null;b(e.result)};e.onabort=e.onerror=function(){e.onabort=null;e.onload=null;e.onerror=null;f(e.error)};e.readAsArrayBuffer(a)})};function wa(){this.b=this.a=void 0;this.c=new TextDecoder("utf-8")}var Q;wa.prototype.decode=function(a){return 2<=a.length&&254===a[0]&&255===a[1]&&(void 0===this.a&&(this.a=xa("utf-16be")),this.a)?this.a.decode(a):2<=a.length&&255===a[0]&&254===a[1]&&(void 0===this.b&&(this.b=xa("utf-16le")),this.b)?this.b.decode(a):this.c.decode(a)};function xa(a){try{return new TextDecoder(a)}catch(b){return console.error(b),null}};function R(a){this.g=a;this.b=new la.BlobUrlManager;this.a=new Map;this.c=new Map;this.f=!1;this.s=1;this.D=new TextEncoder}function ra(a,b,f,e){if(a.f)return S();b=T(a,b);var g=U(a,b,f);e=V(a,e,g);g.v++;e.v++;if(g.h)return g.h;g.h=W(a,b,f,g).then(function(d){X(a,g,g.h);return{data:ya(d),metadata:d.metadata}}).catch(function(d){Y(a,g,g.h)&&(I.Logger.logWarning("Unexpected ResourceCache error"),Z(a,g));throw d;});return g.h}
function sa(a,b,f,e,g){if(a.f)return S();b=T(a,b);var d=U(a,b,f);e=!I.RuntimePlatform.isBlobUrlSupported()||e;g=V(a,g,d);if(e){if(d.B++,g.B++,d.o)return d.o}else if(d.A++,g.A++,d.m)return d.m;!e&&I.RuntimePlatform.isBlobUrlSupported()?(d.h||(d.h=W(a,b,f,d).then(function(c){X(a,d,d.m);return{data:ya(c),metadata:c.metadata}}).catch(function(c){Y(a,d,d.m)&&(I.Logger.logWarning("Unexpected ResourceCache error"),Z(a,d));throw c;})),b=d.h.then(function(c){X(a,d,d.m);d.w=a.b.createUrlForBlob(c.data);return{data:d.w,
metadata:c.metadata}}).catch(function(c){Y(a,d,d.m)&&(I.Logger.logWarning("Unexpected ResourceCache error"),Z(a,d));throw c;})):b=W(a,b,f,d).then(function(c){X(a,d,d.o);return{data:a.b.createUrl([c.data],c.metadata.mediaType||"",!0).url,metadata:c.metadata}}).catch(function(c){Y(a,d,d.o)&&(I.Logger.logWarning("Unexpected ResourceCache error"),Z(a,d));throw c;});e?d.o=b:d.m=b;return b}
function ta(a,b,f,e){if(a.f)return S();b=T(a,b);var g=U(a,b,f);e=V(a,e,g);g.C++;e.C++;if(g.l)return g.l;g.l=W(a,b,f,g).then(function(d){X(a,g,g.l);var c=d.data;"string"!==typeof c&&(Q||(Q=new wa),c=Q.decode(c));return{data:c,metadata:d.metadata}}).catch(function(d){Y(a,g,g.l)&&(I.Logger.logWarning("Unexpected ResourceCache error"),Z(a,g));throw d;});return g.l}
function ua(a,b,f,e){if(a.f)return S();b=T(a,b);var g=U(a,b,f);e=V(a,e,g);g.u++;e.u++;if(g.j)return g.j;g.j=W(a,b,f,g).then(function(d){X(a,g,g.j);var c=d.data;return{data:"string"===typeof c?a.D.encode(c):c,metadata:d.metadata}}).catch(function(d){Y(a,g,g.j)&&(I.Logger.logWarning("Unexpected ResourceCache error"),Z(a,g));throw d;});return g.j}
function qa(a,b){var f=a.c.get(b);a.c.delete(b);f&&f.forEach(function(e,g){var d=a.a.get(g);d&&(d.u-=e.u,d.v-=e.v,d.A-=e.A,d.B-=e.B,d.C-=e.C,e=0,0===d.u&&(e++,d.j=null),0===d.A&&(e++,d.w&&(a.b.revokeUrl(d.w),d.w=null,d.m=null),0===d.v&&(e++,d.h=null)),0===d.B&&(e++,d.o=null),0===d.C&&(e++,d.l=null),5===e&&(d.i&&(d.i.abort(),d.i=null),a.a.delete(g)))})}R.prototype.destroy=function(){this.f||(this.f=!0,this.a.forEach(function(a){a.i&&(a.i.abort(),a.i=null)}),this.a.clear(),this.c.clear(),this.b.revokeAllUrls())};
R.prototype.getBackingResourceProvider=function(){return this.g};function ya(a){var b=a.metadata.mediaType;return b?new Blob([a.data],{type:b}):new Blob([a.data])}function Z(a,b){var f=b.F;b.i&&(b.i.abort(),b.i=null);a.a.get(f)===b&&(a.a.delete(f),b.w&&a.b.revokeUrl(b.w),a.c.forEach(function(e){e.delete(f)}))}
function W(a,b,f,e){var g;e.j?g=e.j:e.l?g=e.l:e.h&&e.H?g=e.h.then(function(d){return va(d.data).then(function(c){return{data:new Uint8Array(c),metadata:d.metadata}})}):e.o?g=e.o.then(function(d){X(a,e);var c=d.data,h=c.indexOf(","),k=null;if(-1!==h){var l=c.slice(0,h);k=decodeURIComponent(c.slice(h+1));l.endsWith("base64")&&(k=I.Base64.decode(k))}return null===k?za(a,b,f,e):{data:k,metadata:d.metadata}}):g=za(a,b,f,e);return g}
function za(a,b,f,e){var g,d;return ka(new ja(new ha(function(c){if(1==c.f)return fa(c,a.g.fetch(b,{accept:f}));g=c.s;if(!Y(a,e))throw g.abort(),new J(I.ColibrioErrorType.ABORTED);d=g.getMetadata();e.i=g;e.H=d;return c.return(g.asUint8Array().then(function(h){return{data:h,metadata:d}}).finally(function(){e.i=null}))})))}function V(a,b,f){var e=a.c.get(b);e||(e=new Map,a.c.set(b,e));a=e.get(f.F);a||(a={u:0,v:0,A:0,B:0,C:0},e.set(f.F,a));return a}
function S(){return Promise.reject(new J("RESOURCE_CACHE_HOST_DESTROYED"))}function U(a,b,f){b=ma.URLUtils.getUrlWithoutHash(b);f&&0<f.length&&(b+="#"+f.join("&"));f=b;b=a.a.get(f);b||(b={u:0,j:null,i:null,h:null,v:0,w:null,A:0,m:null,F:f,B:0,o:null,H:null,C:0,l:null},a.a.set(f,b));return b}function Y(a,b,f){return a.a.get(b.F)===b&&null!==f}function T(a,b){"string"===typeof b&&(b=new URL(b,a.g.getBaseUrl()||void 0),ma.URLUtils.normalizePathnamePercentEncoding(b));return b}
function X(a,b,f){if(!Y(a,b,f))throw new J(I.ColibrioErrorType.ABORTED);};api={ResourceCacheClient:pa,ResourceCacheHost:R,ResourceCacheError:J,ResourceCacheErrorType:{RESOURCE_CACHE_HOST_DESTROYED:"RESOURCE_CACHE_HOST_DESTROYED"}};var Aa=new ArrayBuffer(8);new DataView(Aa);new Uint8Array(Aa); }).call(__self); return api; }));
