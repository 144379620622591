/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base'], function (a0) { return f(r, a0); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.selector = r.colibrio.core.selector || {}; r.colibrio.core.selector.base = f(r, colibrio.core.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base) { var api = {}; (function() { 'use strict';var l;function p(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function q(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:p(a)}}function u(a){if(!(a instanceof Array)){a=q(a);for(var b,c=[];!(b=a.next()).done;)c.push(b.value);a=c}return a}var v="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},w;
if("function"==typeof Object.setPrototypeOf)w=Object.setPrototypeOf;else{var x;a:{var y={s:!0},z={};try{z.__proto__=y;x=z.s;break a}catch(a){}x=!1}w=x?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var A=w,B="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,F="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};
function G(a,b){if(b){var c=B;a=a.split(".");for(var e=0;e<a.length-1;e++){var g=a[e];g in c||(c[g]={});c=c[g]}a=a[a.length-1];e=c[a];b=b(e);b!=e&&null!=b&&F(c,a,{configurable:!0,writable:!0,value:b})}}
G("Promise",function(a){function b(d){this.b=0;this.j=void 0;this.a=[];var f=this.c();try{d(f.resolve,f.reject)}catch(h){f.reject(h)}}function c(){this.a=null}function e(d){return d instanceof b?d:new b(function(f){f(d)})}if(a)return a;c.prototype.b=function(d){if(null==this.a){this.a=[];var f=this;this.c(function(){f.j()})}this.a.push(d)};var g=B.setTimeout;c.prototype.c=function(d){g(d,0)};c.prototype.j=function(){for(;this.a&&this.a.length;){var d=this.a;this.a=[];for(var f=0;f<d.length;++f){var h=
d[f];d[f]=null;try{h()}catch(m){this.i(m)}}}this.a=null};c.prototype.i=function(d){this.c(function(){throw d;})};b.prototype.c=function(){function d(m){return function(n){h||(h=!0,m.call(f,n))}}var f=this,h=!1;return{resolve:d(this.w),reject:d(this.i)}};b.prototype.w=function(d){if(d===this)this.i(new TypeError("A Promise cannot resolve to itself"));else if(d instanceof b)this.A(d);else{a:switch(typeof d){case "object":var f=null!=d;break a;case "function":f=!0;break a;default:f=!1}f?this.v(d):this.m(d)}};
b.prototype.v=function(d){var f=void 0;try{f=d.then}catch(h){this.i(h);return}"function"==typeof f?this.B(f,d):this.m(d)};b.prototype.i=function(d){this.o(2,d)};b.prototype.m=function(d){this.o(1,d)};b.prototype.o=function(d,f){if(0!=this.b)throw Error("Cannot settle("+d+", "+f+"): Promise already settled in state"+this.b);this.b=d;this.j=f;this.u()};b.prototype.u=function(){if(null!=this.a){for(var d=0;d<this.a.length;++d)k.b(this.a[d]);this.a=null}};var k=new c;b.prototype.A=function(d){var f=this.c();
d.l(f.resolve,f.reject)};b.prototype.B=function(d,f){var h=this.c();try{d.call(f,h.resolve,h.reject)}catch(m){h.reject(m)}};b.prototype.then=function(d,f){function h(r,t){return"function"==typeof r?function(C){try{m(r(C))}catch(D){n(D)}}:t}var m,n,E=new b(function(r,t){m=r;n=t});this.l(h(d,m),h(f,n));return E};b.prototype.catch=function(d){return this.then(void 0,d)};b.prototype.l=function(d,f){function h(){switch(m.b){case 1:d(m.j);break;case 2:f(m.j);break;default:throw Error("Unexpected state: "+
m.b);}}var m=this;null==this.a?k.b(h):this.a.push(h)};b.resolve=e;b.reject=function(d){return new b(function(f,h){h(d)})};b.race=function(d){return new b(function(f,h){for(var m=q(d),n=m.next();!n.done;n=m.next())e(n.value).l(f,h)})};b.all=function(d){var f=q(d),h=f.next();return h.done?e([]):new b(function(m,n){function E(C){return function(D){r[C]=D;t--;0==t&&m(r)}}var r=[],t=0;do r.push(void 0),t++,e(h.value).l(E(r.length-1),n),h=f.next();while(!h.done)})};return b});
G("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(c){return Promise.resolve(b()).then(function(){return c})},function(c){return Promise.resolve(b()).then(function(){throw c;})})}});function H(a){return"string"===typeof a&&/\S/.test(a)}var I=Array.isArray;var J=new Uint16Array(1);J[0]=4660;new Uint8Array(J.buffer);function K(a){return!!a&&"string"===typeof a.type};function L(a){for(var b=Object.keys(a),c=[],e=0;e<b.length;e++){var g=b[e],k=a[g];g=M(g)+"=";K(k)?c.push(g+L(k)):I(k)&&0<k.length?(k=k[0],K(k)&&c.push(g+L(k))):"string"===typeof k?c.push(g+M(k)):("number"===typeof k&&!isNaN(k)||"boolean"===typeof k)&&c.push(g+k.toString())}return"selector("+c.join(",")+")"}function M(a){return a.replace(/[ =,#()]/g,function(b){return"%"+b.charCodeAt(0).toString(16)})};function N(){}N.prototype.getType=function(){return"FragmentSelector"};N.prototype.toJSON=function(){var a=this.getRefinementSelectors().map(function(c){return c.toJSON()}),b;1<a.length?b=a:1===a.length&&(b=a[0]);return{refinedBy:b,type:this.getType(),value:this.getValue()}};N.prototype.toString=function(){return 0<this.getRefinementSelectors().length?L(this.toJSON()):this.getValue()};function O(a,b,c){this.h=a;this.a=b;this.b=c}O.prototype=v(N.prototype);O.prototype.constructor=O;if(A)A(O,N);else for(var P in N)if("prototype"!=P)if(Object.defineProperties){var Q=Object.getOwnPropertyDescriptor(N,P);Q&&Object.defineProperty(O,P,Q)}else O[P]=N[P];O.a=N.prototype;l=O.prototype;l.collapseToEnd=function(){return this};l.collapseToStart=function(){return this};l.createRangeSelectorTo=function(){return null};l.getFragmentSpecificationIri=function(){return this.a};
l.getRefinementSelectors=function(){return this.b};l.getValue=function(){return this.h};l.isRangeSelector=function(){return!1};function R(){}R.prototype.canParseSelectorData=function(a){return"FragmentSelector"===a.type};R.prototype.parseSelectorData=function(a,b){if(!H(a.value))return null;var c=null,e=b.createFromSelectorData(a.refinedBy);if(H(a.conformsTo)){var g=b.getFragmentSelectorParsers().find(function(k){return k.getFragmentSpecificationIri()===a.conformsTo&&k.canParseFragment(a.value)});g&&(c=g.parseFragment(a.value,b,e))}c||(c=b.createFromFragment(a.value,e));c||(c=new O(a.value,a.conformsTo||"",e));return c};function S(a,b){this.a=a.collapseToStart();this.b=b.collapseToEnd()}l=S.prototype;l.collapseToEnd=function(){return this.b};l.collapseToStart=function(){return this.a};l.createRangeSelectorTo=function(a){return new S(this.a,a)};l.getEndSelector=function(){return this.b};l.getRefinementSelectors=function(){return[]};l.getStartSelector=function(){return this.a};l.getType=function(){return"RangeSelector"};l.isRangeSelector=function(){return!0};
l.toJSON=function(){return{end:this.b.toJSON(),start:this.a.toJSON(),type:"RangeSelector"}};l.toString=function(){return L(this.toJSON())};function aa(a){this.b=a;this.a=0;this.c=this.b.length}function T(a){for(var b=a.a,c=-1,e="";a.a<a.c&&-1===c;){var g=a.b.charCodeAt(a.a);40===g?(c=1,e=a.b.slice(b,a.a++)):41===g?(c=4,e=a.b.slice(b,a.a)):44===g?(c=4,e=a.b.slice(b,a.a++)):a.a++}return{h:e,f:c}}
function U(a){var b={};do{var c=a;for(var e=-1,g="";c.a<c.c&&44===c.b.charCodeAt(c.a);)c.a++;for(var k=c.a;c.a<c.c&&-1===e;){var d=c.b.charCodeAt(c.a);61===d?(e=3,g=c.b.slice(k,c.a++)):(41===d&&(e=2),c.a++)}c={h:g,f:e};3===c.f&&(e=decodeURIComponent(c.h),c=T(a),4===c.f?b[e]=decodeURIComponent(c.h):1===c.f&&(b[e]=U(a)))}while(-1!==c.f&&2!==c.f);return b};function V(){}V.prototype.canParseFragment=function(a){return a.startsWith("selector(")||a.startsWith("state(")};V.prototype.getFragmentSpecificationIri=function(){return""};V.prototype.parseFragment=function(a,b){a=new aa(a);a=1===T(a).f?U(a):null;b=b.createFromSelectorData(a);return 0<b.length?b[0]:null};function W(a){this.a=[];this.b=[];this.b.push(new V);for(var b=0;b<a.length;b++){var c=a[b];"function"===typeof c.canParseFragment&&this.b.push(c);"function"===typeof c.canParseSelectorData&&this.a.push(c)}this.a.push(new R)}l=W.prototype;l.createFromFragment=function(a,b){a.startsWith("#")&&(a=a.slice(1));for(var c=null,e=0;!c&&e<this.b.length;e++){var g=this.b[e];g.canParseFragment(a)&&(c=g.parseFragment(a,this,b||[]))}return c};
l.createFromSelectorData=function(a){var b=[];if(I(a))for(var c=0;c<a.length;c++){if(K(a[c])){var e=X(this,a[c]);e&&b.push(e)}}else K(a)&&(a=X(this,a))&&b.push(a);return b};l.createRangeSelectors=function(a,b){for(var c=[],e={},g=0;g<a.length;e={g:e.g},g++){e.g=a[g];for(var k=null,d=0;!k&&d<b.length;d++){var f=b[d];(k=e.g.createRangeSelectorTo(f))||e.g.getType()!==f.getType()||(k=new S(e.g,f))}k?c.push(k):c.push.apply(c,u(b.map(function(h){return function(m){return new S(h.g,m)}}(e))))}return c};
l.getFragmentSelectorParsers=function(){return this.b};l.getSelectorDataParsers=function(){return this.a};function X(a,b){for(var c=null,e=0;!c&&e<a.a.length;e++){var g=a.a[e];g.canParseSelectorData(b)&&(c=g.parseSelectorData(b,a))}return c};function Y(){}Y.prototype.canParseSelectorData=function(a){return"RangeSelector"===a.type};Y.prototype.parseSelectorData=function(a,b){if(!K(a.start)||!K(a.end))return null;var c=b.createFromSelectorData(a.start);a=b.createFromSelectorData(a.end);return 1!==c.length||1!==a.length?null:new S(c[0],a[0])};api={FragmentSelector:N,FragmentSpecificationIri:{CSV:"http://tools.ietf.org/rfc/rfc7111",EPUB3:"http://www.idpf.org/epub/linking/cfi/epub-cfi.html",HTML:"http://tools.ietf.org/rfc/rfc3236",MEDIA:"http://www.w3.org/TR/media-frags/",PDF:"http://tools.ietf.org/rfc/rfc3778",PLAIN_TEXT:"http://tools.ietf.org/rfc/rfc5147",RDF_XML:"http://tools.ietf.org/rfc/rfc3870",SVG:"http://www.w3.org/TR/SVG/",XML:"http://tools.ietf.org/rfc/rfc3023"},RangeSelector:S,RangeSelectorDataParser:Y,SelectorFactory:W,SelectorType:{CSS_SELECTOR:"CssSelector",
FRAGMENT_SELECTOR:"FragmentSelector",RANGE_SELECTOR:"RangeSelector",SVG_SELECTOR:"SvgSelector",TEXT_QUOTE_SELECTOR:"TextQuoteSelector",TEXT_POSITION_SELECTOR:"TextPositionSelector",XPATH_SELECTOR:"XPathSelector"},UnknownFragmentSelector:O};var Z=new ArrayBuffer(8);new DataView(Z);new Uint8Array(Z); }).call(__self); return api; }));
