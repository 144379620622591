/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base', './colibrio-core-url'], function (a0, a1, a2) { return f(r, a0, a1, a2); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base'), require('./colibrio-core-url')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.drm = f(r, colibrio.core.base, colibrio.core.io.base, colibrio.core.url); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base,__dep__colibrio_core_url) { var api = {}; (function() { 'use strict';var l;function n(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function q(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:n(a)}}var aa="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},u;
if("function"==typeof Object.setPrototypeOf)u=Object.setPrototypeOf;else{var v;a:{var ba={m:!0},w={};try{w.__proto__=ba;v=w.m;break a}catch(a){}v=!1}u=v?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var x=u,y="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,z="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,d){a!=Array.prototype&&a!=Object.prototype&&(a[b]=d.value)};
function A(a,b){if(b){var d=y;a=a.split(".");for(var f=0;f<a.length-1;f++){var h=a[f];h in d||(d[h]={});d=d[h]}a=a[a.length-1];f=d[a];b=b(f);b!=f&&null!=b&&z(d,a,{configurable:!0,writable:!0,value:b})}}
A("Promise",function(a){function b(c){this.b=0;this.g=void 0;this.a=[];var e=this.c();try{c(e.resolve,e.reject)}catch(g){e.reject(g)}}function d(){this.a=null}function f(c){return c instanceof b?c:new b(function(e){e(c)})}if(a)return a;d.prototype.b=function(c){if(null==this.a){this.a=[];var e=this;this.c(function(){e.g()})}this.a.push(c)};var h=y.setTimeout;d.prototype.c=function(c){h(c,0)};d.prototype.g=function(){for(;this.a&&this.a.length;){var c=this.a;this.a=[];for(var e=0;e<c.length;++e){var g=
c[e];c[e]=null;try{g()}catch(k){this.f(k)}}}this.a=null};d.prototype.f=function(c){this.c(function(){throw c;})};b.prototype.c=function(){function c(k){return function(m){g||(g=!0,k.call(e,m))}}var e=this,g=!1;return{resolve:c(this.w),reject:c(this.f)}};b.prototype.w=function(c){if(c===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(c instanceof b)this.A(c);else{a:switch(typeof c){case "object":var e=null!=c;break a;case "function":e=!0;break a;default:e=!1}e?this.v(c):this.h(c)}};
b.prototype.v=function(c){var e=void 0;try{e=c.then}catch(g){this.f(g);return}"function"==typeof e?this.B(e,c):this.h(c)};b.prototype.f=function(c){this.i(2,c)};b.prototype.h=function(c){this.i(1,c)};b.prototype.i=function(c,e){if(0!=this.b)throw Error("Cannot settle("+c+", "+e+"): Promise already settled in state"+this.b);this.b=c;this.g=e;this.u()};b.prototype.u=function(){if(null!=this.a){for(var c=0;c<this.a.length;++c)r.b(this.a[c]);this.a=null}};var r=new d;b.prototype.A=function(c){var e=this.c();
c.j(e.resolve,e.reject)};b.prototype.B=function(c,e){var g=this.c();try{c.call(e,g.resolve,g.reject)}catch(k){g.reject(k)}};b.prototype.then=function(c,e){function g(p,t){return"function"==typeof p?function(B){try{k(p(B))}catch(C){m(C)}}:t}var k,m,D=new b(function(p,t){k=p;m=t});this.j(g(c,k),g(e,m));return D};b.prototype.catch=function(c){return this.then(void 0,c)};b.prototype.j=function(c,e){function g(){switch(k.b){case 1:c(k.g);break;case 2:e(k.g);break;default:throw Error("Unexpected state: "+
k.b);}}var k=this;null==this.a?r.b(g):this.a.push(g)};b.resolve=f;b.reject=function(c){return new b(function(e,g){g(c)})};b.race=function(c){return new b(function(e,g){for(var k=q(c),m=k.next();!m.done;m=k.next())f(m.value).j(e,g)})};b.all=function(c){var e=q(c),g=e.next();return g.done?f([]):new b(function(k,m){function D(B){return function(C){p[B]=C;t--;0==t&&k(p)}}var p=[],t=0;do p.push(void 0),t++,f(g.value).j(D(p.length-1),m),g=e.next();while(!g.done)})};return b});
A("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(d){return Promise.resolve(b()).then(function(){return d})},function(d){return Promise.resolve(b()).then(function(){throw d;})})}});function E(){E=function(){};y.Symbol||(y.Symbol=ca)}function F(a,b){this.a=a;z(this,"description",{configurable:!0,writable:!0,value:b})}F.prototype.toString=function(){return this.a};
var ca=function(){function a(d){if(this instanceof a)throw new TypeError("Symbol is not a constructor");return new F("jscomp_symbol_"+(d||"")+"_"+b++,d)}var b=0;return a}();function G(){E();var a=y.Symbol.iterator;a||(a=y.Symbol.iterator=y.Symbol("Symbol.iterator"));"function"!=typeof Array.prototype[a]&&z(Array.prototype,a,{configurable:!0,writable:!0,value:function(){return da(n(this))}});G=function(){}}function da(a){G();a={next:a};a[y.Symbol.iterator]=function(){return this};return a}
function H(){this.f=!1;this.b=null;this.g=void 0;this.a=1;this.i=0;this.c=null}function I(a){if(a.f)throw new TypeError("Generator is already running");a.f=!0}H.prototype.h=function(a){this.g=a};function J(a,b){a.c={o:b,s:!0};a.a=a.i}H.prototype.return=function(a){this.c={return:a};this.a=this.i};function K(a){this.a=new H;this.b=a}function ea(a,b){I(a.a);var d=a.a.b;if(d)return L(a,"return"in d?d["return"]:function(f){return{value:f,done:!0}},b,a.a.return);a.a.return(b);return M(a)}
function L(a,b,d,f){try{var h=b.call(a.a.b,d);if(!(h instanceof Object))throw new TypeError("Iterator result "+h+" is not an object");if(!h.done)return a.a.f=!1,h;var r=h.value}catch(c){return a.a.b=null,J(a.a,c),M(a)}a.a.b=null;f.call(a.a,r);return M(a)}function M(a){for(;a.a.a;)try{var b=a.b(a.a);if(b)return a.a.f=!1,{value:b.value,done:!1}}catch(d){a.a.g=void 0,J(a.a,d)}a.a.f=!1;if(a.a.c){b=a.a.c;a.a.c=null;if(b.s)throw b.o;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function N(a){this.next=function(b){I(a.a);a.a.b?b=L(a,a.a.b.next,b,a.a.h):(a.a.h(b),b=M(a));return b};this.throw=function(b){I(a.a);a.a.b?b=L(a,a.a.b["throw"],b,a.a.h):(J(a.a,b),b=M(a));return b};this.return=function(b){return ea(a,b)};G();this[Symbol.iterator]=function(){return this}}function O(a){function b(f){return a.next(f)}function d(f){return a.throw(f)}return new Promise(function(f,h){function r(c){c.done?f(c.value):Promise.resolve(c.value).then(b,d).then(r,h)}r(a.next())})};var P=__dep__colibrio_core_base;var fa=__dep__colibrio_core_io_base;var ha=__dep__colibrio_core_url;var Q=new Uint16Array(1);Q[0]=4660;new Uint8Array(Q.buffer);function R(a,b){return P.ColibrioError.call(this,"colibrio.core.drm",a,b)||this}var S=P.ColibrioError;R.prototype=aa(S.prototype);R.prototype.constructor=R;if(x)x(R,S);else for(var T in S)if("prototype"!=T)if(Object.defineProperties){var U=Object.getOwnPropertyDescriptor(S,T);U&&Object.defineProperty(R,T,U)}else R[T]=S[T];R.a=S.prototype;function V(){this.b=this.a=void 0;this.c=new TextDecoder("utf-8")}var W;V.prototype.decode=function(a){return 2<=a.length&&254===a[0]&&255===a[1]&&(void 0===this.a&&(this.a=X("utf-16be")),this.a)?this.a.decode(a):2<=a.length&&255===a[0]&&254===a[1]&&(void 0===this.b&&(this.b=X("utf-16le")),this.b)?this.b.decode(a):this.c.decode(a)};function X(a){try{return new TextDecoder(a)}catch(b){return console.error(b),null}};function ia(a,b,d){this.f=a;this.a=b;this.c=d;this.l=!1;this.b=null}l=ia.prototype;l.abort=function(){this.l=!0;this.a.abort()};l.asText=function(){return Y(this).then(function(a){W||(W=new V);return W.decode(a)})};l.asUint8Array=function(){return Y(this)};l.getMetadata=function(){return this.f};
l.getReadableStream=function(){var a=this;this.b||(this.b=new ReadableStream({cancel:function(){a.a.abort()},start:function(b){var d;return O(new N(new K(function(f){if(1==f.a){if(a.l){b.error(new R(P.ColibrioErrorType.ABORTED));f.a=0;return}var h=Y(a);f.a=3;return{value:h}}d=f.g;b.enqueue(d);b.close();f.a=0})))}}));return this.b};
function Y(a){var b;return O(new N(new K(function(d){if(1==d.a){var f=a.a.asUint8Array();d.a=2;return{value:f}}b=d.g;return d.return(a.c.decrypt(b,a.a.getMetadata()))})))};function ja(a){this.c=a;this.b=new Map;this.a=null}l=ja.prototype;l.addEncryptionMethod=function(a){this.b.set(a.getName(),a)};l.getDecryptedResourceMetadata=function(a){return this.a?this.a.getEncryptedDataEntryByUri(a.url)?ka(a):a:a};
l.getDecryptedResourceResponse=function(a){if(!this.a)return a;var b=this.a.getEncryptedDataEntryByUri(a.getMetadata().url);if(b&&b.algorithmName){var d=this.b.get(b.algorithmName);d?(b=ka(a.getMetadata()),a=new ia(b,a,d)):a=this.c?this.c(a,b):new fa.ErrorResourceResponse(a.getMetadata(),new R("ENCRYPTION_ALGORITHM_NOT_SUPPORTED","Algorithm: "+b.algorithmName))}return a};l.getEncryptionMethod=function(a){return this.b.get(a)};l.getEncryptionMethods=function(){return Array.from(this.b.values())};
l.getXmlEncryptionInfo=function(){return this.a};l.isEncrypted=function(a){var b;this.a&&(b=this.a.getEncryptedDataEntryByUri(a.toString()));return void 0!=b};l.removeEncryptionMethod=function(a){this.b.delete(a)};l.setXmlEncryptionInfo=function(a){this.a=a};function ka(a){a=Object.assign({},a);a.acceptsRanges=!1;return a};function Z(a,b){this.c=b;this.a=new Map;this.b=[];for(a=a.documentElement.firstChild;a;){if(a&&1===a.nodeType&&a.namespaceURI===P.XmlNamespace.XML_ENCRYPTION&&"EncryptedData"===a.localName){for(var d=a.firstChild,f=b=null;d;){if(d&&1===d.nodeType&&d.namespaceURI===P.XmlNamespace.XML_ENCRYPTION)if("EncryptionMethod"===d.localName)b=d.getAttribute("Algorithm");else if("CipherData"===d.localName){var h=d.firstElementChild;h&&"CipherReference"===h.localName&&(f=h.getAttribute("URI"))}d=d.nextSibling}f&&
b?(d=new URL(f,this.c),ha.URLUtils.normalizePathnamePercentEncoding(d),b={algorithmName:b,cipherRefUrl:d.toString(),cipherRefUrlRaw:f}):b=null;b&&(this.b.push(b),this.a.set(b.cipherRefUrl,b))}a=a.nextSibling}}Z.prototype.getEncryptedDataEntries=function(){return this.b.slice()};Z.prototype.getEncryptedDataEntryByUri=function(a){return this.a.get(a)};api={DrmError:R,DrmErrorType:{ENCRYPTION_ALGORITHM_NOT_SUPPORTED:"ENCRYPTION_ALGORITHM_NOT_SUPPORTED"},DrmManager:ja,XmlEncryptionInfo:Z};var la=new ArrayBuffer(8);new DataView(la);new Uint8Array(la); }).call(__self); return api; }));
