/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-selector-base', './colibrio-core-url', './colibrio-core-io-base'], function (a0, a1, a2, a3) { return f(r, a0, a1, a2, a3); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-selector-base'), require('./colibrio-core-url'), require('./colibrio-core-io-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.locator = f(r, colibrio.core.base, colibrio.core.selector.base, colibrio.core.url, colibrio.core.io.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base,__dep__colibrio_core_selector_base,__dep__colibrio_core_url,__dep__colibrio_core_io_base) { var api = {}; (function() { 'use strict';var k;function l(b){var a=0;return function(){return a<b.length?{done:!1,value:b[a++]}:{done:!0}}}function q(b){var a="undefined"!=typeof Symbol&&Symbol.iterator&&b[Symbol.iterator];return a?a.call(b):{next:l(b)}}var t="function"==typeof Object.create?Object.create:function(b){function a(){}a.prototype=b;return new a},u;
if("function"==typeof Object.setPrototypeOf)u=Object.setPrototypeOf;else{var w;a:{var x={l:!0},y={};try{y.__proto__=x;w=y.l;break a}catch(b){}w=!1}u=w?function(b,a){b.__proto__=a;if(b.__proto__!==a)throw new TypeError(b+" is not extensible");return b}:null}var C=u,D="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,E="function"==typeof Object.defineProperties?Object.defineProperty:function(b,a,e){b!=Array.prototype&&b!=Object.prototype&&(b[a]=e.value)};
function F(b,a){if(a){var e=D;b=b.split(".");for(var h=0;h<b.length-1;h++){var n=b[h];n in e||(e[n]={});e=e[n]}b=b[b.length-1];h=e[b];a=a(h);a!=h&&null!=a&&E(e,b,{configurable:!0,writable:!0,value:a})}}
F("Promise",function(b){function a(c){this.b=0;this.g=void 0;this.a=[];var d=this.c();try{c(d.resolve,d.reject)}catch(f){d.reject(f)}}function e(){this.a=null}function h(c){return c instanceof a?c:new a(function(d){d(c)})}if(b)return b;e.prototype.b=function(c){if(null==this.a){this.a=[];var d=this;this.c(function(){d.g()})}this.a.push(c)};var n=D.setTimeout;e.prototype.c=function(c){n(c,0)};e.prototype.g=function(){for(;this.a&&this.a.length;){var c=this.a;this.a=[];for(var d=0;d<c.length;++d){var f=
c[d];c[d]=null;try{f()}catch(g){this.f(g)}}}this.a=null};e.prototype.f=function(c){this.c(function(){throw c;})};a.prototype.c=function(){function c(g){return function(m){f||(f=!0,g.call(d,m))}}var d=this,f=!1;return{resolve:c(this.s),reject:c(this.f)}};a.prototype.s=function(c){if(c===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(c instanceof a)this.u(c);else{a:switch(typeof c){case "object":var d=null!=c;break a;case "function":d=!0;break a;default:d=!1}d?this.o(c):this.i(c)}};
a.prototype.o=function(c){var d=void 0;try{d=c.then}catch(f){this.f(f);return}"function"==typeof d?this.v(d,c):this.i(c)};a.prototype.f=function(c){this.j(2,c)};a.prototype.i=function(c){this.j(1,c)};a.prototype.j=function(c,d){if(0!=this.b)throw Error("Cannot settle("+c+", "+d+"): Promise already settled in state"+this.b);this.b=c;this.g=d;this.m()};a.prototype.m=function(){if(null!=this.a){for(var c=0;c<this.a.length;++c)v.b(this.a[c]);this.a=null}};var v=new e;a.prototype.u=function(c){var d=this.c();
c.h(d.resolve,d.reject)};a.prototype.v=function(c,d){var f=this.c();try{c.call(d,f.resolve,f.reject)}catch(g){f.reject(g)}};a.prototype.then=function(c,d){function f(p,r){return"function"==typeof p?function(z){try{g(p(z))}catch(A){m(A)}}:r}var g,m,B=new a(function(p,r){g=p;m=r});this.h(f(c,g),f(d,m));return B};a.prototype.catch=function(c){return this.then(void 0,c)};a.prototype.h=function(c,d){function f(){switch(g.b){case 1:c(g.g);break;case 2:d(g.g);break;default:throw Error("Unexpected state: "+
g.b);}}var g=this;null==this.a?v.b(f):this.a.push(f)};a.resolve=h;a.reject=function(c){return new a(function(d,f){f(c)})};a.race=function(c){return new a(function(d,f){for(var g=q(c),m=g.next();!m.done;m=g.next())h(m.value).h(d,f)})};a.all=function(c){var d=q(c),f=d.next();return f.done?h([]):new a(function(g,m){function B(z){return function(A){p[z]=A;r--;0==r&&g(p)}}var p=[],r=0;do p.push(void 0),r++,h(f.value).h(B(p.length-1),m),f=d.next();while(!f.done)})};return a});
F("Promise.prototype.finally",function(b){return b?b:function(a){return this.then(function(e){return Promise.resolve(a()).then(function(){return e})},function(e){return Promise.resolve(a()).then(function(){throw e;})})}});var G=__dep__colibrio_core_base;var H=__dep__colibrio_core_url;var I=Array.isArray;function J(b){return"string"===typeof b};var K=new Uint16Array(1);K[0]=4660;new Uint8Array(K.buffer);function L(b,a){this.b=b;this.a=a}k=L.prototype;k.getSelectors=function(){return this.a};k.getSourceUrl=function(){return this.b};k.toJSON=function(){return{sourceUrl:this.b,selectors:this.a.map(function(b){return b.toString()})}};k.toLegacyAnnotationTarget=function(){return 0<this.a.length?{id:this.b,selector:this.a[0].toJSON()}:{id:this.b}};k.toString=function(b){J(b)||(b=this.b);return 0===this.a.length?b:b+"#"+this.a[0].toString()};k.toUrl=function(b){return new URL(this.toString(b))};
k.toWebAnnotationResource=function(){var b=this.b;return{id:0===this.a.length?b:b+"#"+this.a[0].toString()}};k.toWebAnnotationSpecificResource=function(){return{source:this.b,selector:this.a.map(function(b){return b.toJSON()})}};function M(b,a){return G.ColibrioError.call(this,"core.locator",b,a)||this}var N=G.ColibrioError;M.prototype=t(N.prototype);M.prototype.constructor=M;if(C)C(M,N);else for(var O in N)if("prototype"!=O)if(Object.defineProperties){var P=Object.getOwnPropertyDescriptor(N,O);P&&Object.defineProperty(M,O,P)}else M[O]=N[O];M.a=N.prototype;function Q(b){this.a=b}k=Q.prototype;k.collapseRangeLocatorToEnd=function(b){var a=b.getSelectors().map(function(e){return e.collapseToEnd()});return new L(b.getSourceUrl(),a)};k.collapseRangeLocatorToStart=function(b){var a=b.getSelectors().map(function(e){return e.collapseToStart()});return new L(b.getSourceUrl(),a)};
k.createRangeLocator=function(b,a){return b.getSourceUrl()!==a.getSourceUrl()?{locator:null,error:new M("INVALID_URL","startLocator and endLocator have different sourceUrls.")}:(a=this.a.createRangeSelectors(b.getSelectors(),a.getSelectors()))&&0!==a.length?{locator:new L(b.getSourceUrl(),a),error:null}:{locator:null,error:new M("UNABLE_TO_CREATE_RANGE_SELECTORS","Unable to create rangeSelectors")}};k.getSelectorFactory=function(){return this.a};
k.parse=function(b,a){b=R(this,b,a);if(b instanceof L)return{error:null,locator:b};G.Logger.logError(b);return{error:b,locator:null}};
function S(b,a,e){var h=null,n=!0;if(J(a.sourceUrl)&&I(a.selectors))try{h=new URL(a.sourceUrl);var v=a.selectors;var c=[];for(e=0;e<v.length;e++){var d=v[e];if(J(d)){var f=b.a.createFromFragment(d);f&&c.push(f)}}}catch(g){return new M("INVALID_LOCATOR_DATA",'Invalid "sourceUrl": '+a.sourceUrl)}else if(J(a.source))try{h=new URL(a.source)}catch(g){return new M("INVALID_LOCATOR_DATA",'Invalid "source": '+a.source)}else if(J(a.id))try{h=new URL(a.id),n=!h.hash||"#"===h.hash}catch(g){return new M("INVALID_LOCATOR_DATA",
'Invalid "id": '+a.id)}else if(e&&a.selector)if(e instanceof URL)h=e;else try{h=new URL(e)}catch(g){return new M("INVALID_URL","Invalid baseUrl: "+e)}else return new M("INVALID_LOCATOR_DATA",'Missing "source" or "id"');c||(c=n&&"undefined"!==typeof a.selector?b.a.createFromSelectorData(a.selector):[]);return T(b,h,c)}function T(b,a,e){(a.hash||"#"!==a.hash)&&(b=b.a.createFromFragment(a.hash,e))&&(e=[b]);return new L(H.URLUtils.getUrlWithoutHash(a),e)}
function R(b,a,e){if(a instanceof L)return a;if(a instanceof URL)return T(b,a,[]);if(J(a))try{var h=new URL(a,e);return T(b,h,[])}catch(n){return new M("INVALID_URL",a)}else return a&&!I(a)&&"object"===typeof a?S(b,a,e):new M("INVALID_LOCATOR_DATA","Must be an object or string. Got: "+typeof a)};api={Locator:L,LocatorError:M,LocatorErrorType:{INVALID_URL:"INVALID_URL",INVALID_LOCATOR_DATA:"INVALID_LOCATOR_DATA",UNABLE_TO_CREATE_RANGE_SELECTORS:"UNABLE_TO_CREATE_RANGE_SELECTORS"},LocatorFactory:Q};var U=new ArrayBuffer(8);new DataView(U);new Uint8Array(U); }).call(__self); return api; }));
