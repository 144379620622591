/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define([], function () { return f(r); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.encryption = f(r); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self) { var api = {}; (function() { 'use strict';function p(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function q(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:p(a)}}var r="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},v;
if("function"==typeof Object.setPrototypeOf)v=Object.setPrototypeOf;else{var w;a:{var x={m:!0},y={};try{y.__proto__=x;w=y.m;break a}catch(a){}w=!1}v=w?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var z=v;
function C(a,b){a.prototype=r(b.prototype);a.prototype.constructor=a;if(z)z(a,b);else for(var c in b)if("prototype"!=c)if(Object.defineProperties){var d=Object.getOwnPropertyDescriptor(b,c);d&&Object.defineProperty(a,c,d)}else a[c]=b[c];a.w=b.prototype}var D="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,E="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,c){a!=Array.prototype&&a!=Object.prototype&&(a[b]=c.value)};
function F(a,b){if(b){var c=D;a=a.split(".");for(var d=0;d<a.length-1;d++){var g=a[d];g in c||(c[g]={});c=c[g]}a=a[a.length-1];d=c[a];b=b(d);b!=d&&null!=b&&E(c,a,{configurable:!0,writable:!0,value:b})}}
F("Promise",function(a){function b(e){this.b=0;this.g=void 0;this.a=[];var f=this.c();try{e(f.resolve,f.reject)}catch(h){f.reject(h)}}function c(){this.a=null}function d(e){return e instanceof b?e:new b(function(f){f(e)})}if(a)return a;c.prototype.b=function(e){if(null==this.a){this.a=[];var f=this;this.c(function(){f.g()})}this.a.push(e)};var g=D.setTimeout;c.prototype.c=function(e){g(e,0)};c.prototype.g=function(){for(;this.a&&this.a.length;){var e=this.a;this.a=[];for(var f=0;f<e.length;++f){var h=
e[f];e[f]=null;try{h()}catch(l){this.f(l)}}}this.a=null};c.prototype.f=function(e){this.c(function(){throw e;})};b.prototype.c=function(){function e(l){return function(m){h||(h=!0,l.call(f,m))}}var f=this,h=!1;return{resolve:e(this.s),reject:e(this.f)}};b.prototype.s=function(e){if(e===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(e instanceof b)this.u(e);else{a:switch(typeof e){case "object":var f=null!=e;break a;case "function":f=!0;break a;default:f=!1}f?this.o(e):this.h(e)}};
b.prototype.o=function(e){var f=void 0;try{f=e.then}catch(h){this.f(h);return}"function"==typeof f?this.v(f,e):this.h(e)};b.prototype.f=function(e){this.i(2,e)};b.prototype.h=function(e){this.i(1,e)};b.prototype.i=function(e,f){if(0!=this.b)throw Error("Cannot settle("+e+", "+f+"): Promise already settled in state"+this.b);this.b=e;this.g=f;this.j()};b.prototype.j=function(){if(null!=this.a){for(var e=0;e<this.a.length;++e)k.b(this.a[e]);this.a=null}};var k=new c;b.prototype.u=function(e){var f=this.c();
e.l(f.resolve,f.reject)};b.prototype.v=function(e,f){var h=this.c();try{e.call(f,h.resolve,h.reject)}catch(l){h.reject(l)}};b.prototype.then=function(e,f){function h(n,t){return"function"==typeof n?function(A){try{l(n(A))}catch(B){m(B)}}:t}var l,m,u=new b(function(n,t){l=n;m=t});this.l(h(e,l),h(f,m));return u};b.prototype.catch=function(e){return this.then(void 0,e)};b.prototype.l=function(e,f){function h(){switch(l.b){case 1:e(l.g);break;case 2:f(l.g);break;default:throw Error("Unexpected state: "+
l.b);}}var l=this;null==this.a?k.b(h):this.a.push(h)};b.resolve=d;b.reject=function(e){return new b(function(f,h){h(e)})};b.race=function(e){return new b(function(f,h){for(var l=q(e),m=l.next();!m.done;m=l.next())d(m.value).l(f,h)})};b.all=function(e){var f=q(e),h=f.next();return h.done?d([]):new b(function(l,m){function u(A){return function(B){n[A]=B;t--;0==t&&l(n)}}var n=[],t=0;do n.push(void 0),t++,d(h.value).l(u(n.length-1),m),h=f.next();while(!h.done)})};return b});
F("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(c){return Promise.resolve(b()).then(function(){return c})},function(c){return Promise.resolve(b()).then(function(){throw c;})})}});var G=new Uint16Array(1);G[0]=4660;new Uint8Array(G.buffer);function H(a){var b=new Uint8Array(a);if("undefined"!==typeof crypto&&crypto.getRandomValues)crypto.getRandomValues(b);else for(var c=0;c<a;c++)b[c]=256*Math.random()|0;return b};function I(a){var b=Error.call(this,"[colibrio.internal] "+(a||""));this.message=b.message;"stack"in b&&(this.stack=b.stack);this.errorMessage=a}C(I,Error);function J(a,b){this.g=!1;this.h=new Uint8Array(64);this.c=this.b=0;this.i=new Int32Array(a);this.a=new Int32Array(b);this.j(this.a)}
J.prototype.append=function(a){if(this.g)throw new I("Illegal Sha.append(). Already finalized.");var b=0;a instanceof ArrayBuffer?a=new Uint8Array(a):a instanceof Uint8Array||("string"===typeof a?(K||(K=new TextEncoder),a=K.encode(a)):a=new Uint8Array(a.buffer,a.byteOffset,a.byteLength));if(a.length>Number.MAX_SAFE_INTEGER)throw new I("Data passed to Sha.append() is too large");var c=a.length;if(0<this.b){b=Math.min(64-this.b,c);for(var d=0;d<b;d++)this.h[this.b++]=a[d];64===this.b&&(this.f(this.h,
0,this.i,this.a),this.b=0)}this.c+=c;if(!(b>=c)){d=c-(c-b)%64;if(b<d){for(;b<d;b+=64)this.f(a,b,this.i,this.a);b>d&&(b-=64)}if(b<c)for(;b<c;)this.h[this.b++]=a[b++]}};J.prototype.digestToHex=function(){L(this);for(var a=Array(2*this.a.byteLength),b=0,c=0;c<this.a.length;c++)for(var d=this.a[c],g=7;0<=g;g--){var k=d>>(g<<2)&15;a[b++]=(10>k?48:87)+k}return String.fromCharCode.apply(null,a)};J.prototype.digestToUint32Array=function(){L(this);return new Uint32Array(this.a)};
J.prototype.digestToUint8Array=function(){L(this);for(var a=new Uint8Array(this.a.byteLength),b=0,c=0;c<this.a.length;c++){var d=this.a[c];a[b++]=d>>>24;a[b++]=d>>>16;a[b++]=d>>>8;a[b++]=d}return a};J.prototype.reset=function(){this.j(this.a);this.g=!1;this.c=this.b=0};
function L(a){if(!a.g){a.g=!0;var b=a.b,c=a.h;c[b++]=128;if(56<b){for(;64>b;)c[b++]=0;a.f(c,0,a.i,a.a);b=0}for(;56>b;)c[b++]=0;if(a.c>Math.pow(2,29)-1){var d=Math.trunc(a.c/Math.pow(2,29))|0;c[b++]=d>>>24;c[b++]=d>>>16;c[b++]=d>>>8;c[b++]=d}else for(;60>b;)c[b++]=0;d=a.c<<3|0;c[b++]=d>>>24;c[b++]=d>>>16;c[b++]=d>>>8;c[b++]=d;a.f(c,0,a.i,a.a)}}var K=null;function M(){J.call(this,80,5)}C(M,J);
M.prototype.f=function(a,b,c,d){for(var g=0;16>g;g++)c[g]=a[b++]<<24|a[b++]<<16|a[b++]<<8|a[b++];for(a=16;80>a;a++)c[a]=N(c[a-3]^c[a-8]^c[a-14]^c[a-16],1);a=d[0];b=d[1];g=d[2];for(var k=d[3],e=d[4],f=0;20>f;f++){var h=N(a,5)+(b&g|~b&k)+e+c[f]+1518500249|0;e=k;k=g;g=N(b,30);b=a;a=h}for(f=20;40>f;f++)h=N(a,5)+(b^g^k)+e+c[f]+1859775393|0,e=k,k=g,g=N(b,30),b=a,a=h;for(f=40;60>f;f++)h=N(a,5)+(b&g|b&k|g&k)+e+c[f]+2400959708|0,e=k,k=g,g=N(b,30),b=a,a=h;for(f=60;80>f;f++)h=N(a,5)+(b^g^k)+e+c[f]+3395469782|
0,e=k,k=g,g=N(b,30),b=a,a=h;d[0]+=a;d[1]+=b;d[2]+=g;d[3]+=k;d[4]+=e};M.prototype.j=function(a){a[0]=1732584193;a[1]=4023233417;a[2]=2562383102;a[3]=271733878;a[4]=3285377520};function N(a,b){return a<<b|a>>>32-b};function O(){J.call(this,64,8)}C(O,J);
O.prototype.f=function(a,b,c,d){for(var g=0;16>g;g++)c[g]=a[b++]<<24|a[b++]<<16|a[b++]<<8|a[b++];for(a=16;64>a;a++)b=c[a-15],g=c[a-2],c[a]=(P(g,17)^P(g,19)^g>>>10)+c[a-7]+(P(b,7)^P(b,18)^b>>>3)+c[a-16];a=d[0];b=d[1];g=d[2];for(var k=d[3],e=d[4],f=d[5],h=d[6],l=d[7],m=0;64>m;m++){var u=l+(P(e,6)^P(e,11)^P(e,25))+(e&f^~e&h)+Q[m]+c[m]|0,n=(P(a,2)^P(a,13)^P(a,22))+(a&b^a&g^b&g)|0;l=h;h=f;f=e;e=k+u|0;k=g;g=b;b=a;a=u+n|0}d[0]+=a;d[1]+=b;d[2]+=g;d[3]+=k;d[4]+=e;d[5]+=f;d[6]+=h;d[7]+=l};
O.prototype.j=function(a){a[0]=1779033703;a[1]=3144134277;a[2]=1013904242;a[3]=2773480762;a[4]=1359893119;a[5]=2600822924;a[6]=528734635;a[7]=1541459225};
var Q=[1116352408,1899447441,3049323471,3921009573,961987163,1508970993,2453635748,2870763221,3624381080,310598401,607225278,1426881987,1925078388,2162078206,2614888103,3248222580,3835390401,4022224774,264347078,604807628,770255983,1249150122,1555081692,1996064986,2554220882,2821834349,2952996808,3210313671,3336571891,3584528711,113926993,338241895,666307205,773529912,1294757372,1396182291,1695183700,1986661051,2177026350,2456956037,2730485921,2820302411,3259730800,3345764771,3516065817,3600352804,
4094571909,275423344,430227734,506948616,659060556,883997877,958139571,1322822218,1537002063,1747873779,1955562222,2024104815,2227730452,2361852424,2428436474,2756734187,3204031479,3329325298];function P(a,b){return a>>>b|a<<32-b};api={Random:{getRandomBytes:H,getRandomHexString:function(a){a=H(a);for(var b=[],c=0;c<a.length;c++)16>a[c]&&b.push("0"),b.push(a[c].toString(16));return b.join("")},getRandomString:function(a,b){for(var c=H(a),d="",g=0;g<a;g++)d+=String.fromCharCode(c[g]);a=btoa(d);b&&(a=a.replace(/[.+\/]/g,function(k){return"."===k?"a":"+"===k?"A":"0"}));return a}},Sha1:M,Sha256:O};var R=new ArrayBuffer(8);new DataView(R);new Uint8Array(R); }).call(__self); return api; }));
