/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base'], function (a0) { return f(r, a0); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.io = r.colibrio.core.io || {}; r.colibrio.core.io.base = f(r, colibrio.core.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base) { var api = {}; (function() { 'use strict';function m(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function p(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:m(a)}}var aa="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},r;
if("function"==typeof Object.setPrototypeOf)r=Object.setPrototypeOf;else{var u;a:{var ba={l:!0},v={};try{v.__proto__=ba;u=v.l;break a}catch(a){}u=!1}r=u?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var w=r,x="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,y="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,d){a!=Array.prototype&&a!=Object.prototype&&(a[b]=d.value)};
function z(a,b){if(b){var d=x;a=a.split(".");for(var f=0;f<a.length-1;f++){var g=a[f];g in d||(d[g]={});d=d[g]}a=a[a.length-1];f=d[a];b=b(f);b!=f&&null!=b&&y(d,a,{configurable:!0,writable:!0,value:b})}}
z("Promise",function(a){function b(c){this.b=0;this.g=void 0;this.a=[];var e=this.c();try{c(e.resolve,e.reject)}catch(h){e.reject(h)}}function d(){this.a=null}function f(c){return c instanceof b?c:new b(function(e){e(c)})}if(a)return a;d.prototype.b=function(c){if(null==this.a){this.a=[];var e=this;this.c(function(){e.g()})}this.a.push(c)};var g=x.setTimeout;d.prototype.c=function(c){g(c,0)};d.prototype.g=function(){for(;this.a&&this.a.length;){var c=this.a;this.a=[];for(var e=0;e<c.length;++e){var h=
c[e];c[e]=null;try{h()}catch(k){this.f(k)}}}this.a=null};d.prototype.f=function(c){this.c(function(){throw c;})};b.prototype.c=function(){function c(k){return function(n){h||(h=!0,k.call(e,n))}}var e=this,h=!1;return{resolve:c(this.v),reject:c(this.f)}};b.prototype.v=function(c){if(c===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(c instanceof b)this.w(c);else{a:switch(typeof c){case "object":var e=null!=c;break a;case "function":e=!0;break a;default:e=!1}e?this.u(c):this.h(c)}};
b.prototype.u=function(c){var e=void 0;try{e=c.then}catch(h){this.f(h);return}"function"==typeof e?this.A(e,c):this.h(c)};b.prototype.f=function(c){this.i(2,c)};b.prototype.h=function(c){this.i(1,c)};b.prototype.i=function(c,e){if(0!=this.b)throw Error("Cannot settle("+c+", "+e+"): Promise already settled in state"+this.b);this.b=c;this.g=e;this.s()};b.prototype.s=function(){if(null!=this.a){for(var c=0;c<this.a.length;++c)l.b(this.a[c]);this.a=null}};var l=new d;b.prototype.w=function(c){var e=this.c();
c.j(e.resolve,e.reject)};b.prototype.A=function(c,e){var h=this.c();try{c.call(e,h.resolve,h.reject)}catch(k){h.reject(k)}};b.prototype.then=function(c,e){function h(q,t){return"function"==typeof q?function(F){try{k(q(F))}catch(G){n(G)}}:t}var k,n,H=new b(function(q,t){k=q;n=t});this.j(h(c,k),h(e,n));return H};b.prototype.catch=function(c){return this.then(void 0,c)};b.prototype.j=function(c,e){function h(){switch(k.b){case 1:c(k.g);break;case 2:e(k.g);break;default:throw Error("Unexpected state: "+
k.b);}}var k=this;null==this.a?l.b(h):this.a.push(h)};b.resolve=f;b.reject=function(c){return new b(function(e,h){h(c)})};b.race=function(c){return new b(function(e,h){for(var k=p(c),n=k.next();!n.done;n=k.next())f(n.value).j(e,h)})};b.all=function(c){var e=p(c),h=e.next();return h.done?f([]):new b(function(k,n){function H(F){return function(G){q[F]=G;t--;0==t&&k(q)}}var q=[],t=0;do q.push(void 0),t++,f(h.value).j(H(q.length-1),n),h=e.next();while(!h.done)})};return b});
z("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(d){return Promise.resolve(b()).then(function(){return d})},function(d){return Promise.resolve(b()).then(function(){throw d;})})}});function A(){A=function(){};x.Symbol||(x.Symbol=ca)}function B(a,b){this.a=a;y(this,"description",{configurable:!0,writable:!0,value:b})}B.prototype.toString=function(){return this.a};
var ca=function(){function a(d){if(this instanceof a)throw new TypeError("Symbol is not a constructor");return new B("jscomp_symbol_"+(d||"")+"_"+b++,d)}var b=0;return a}();function C(){A();var a=x.Symbol.iterator;a||(a=x.Symbol.iterator=x.Symbol("Symbol.iterator"));"function"!=typeof Array.prototype[a]&&y(Array.prototype,a,{configurable:!0,writable:!0,value:function(){return da(m(this))}});C=function(){}}function da(a){C();a={next:a};a[x.Symbol.iterator]=function(){return this};return a}
function D(){this.f=!1;this.a=null;this.h=void 0;this.b=1;this.i=0;this.c=null}function E(a){if(a.f)throw new TypeError("Generator is already running");a.f=!0}D.prototype.g=function(a){this.h=a};function I(a,b){a.c={m:b,o:!0};a.b=a.i}D.prototype.return=function(a){this.c={return:a};this.b=this.i};function ea(a,b){a.b=4;return{value:b}}function fa(a){this.a=new D;this.b=a}
function ha(a,b){E(a.a);var d=a.a.a;if(d)return J(a,"return"in d?d["return"]:function(f){return{value:f,done:!0}},b,a.a.return);a.a.return(b);return K(a)}function J(a,b,d,f){try{var g=b.call(a.a.a,d);if(!(g instanceof Object))throw new TypeError("Iterator result "+g+" is not an object");if(!g.done)return a.a.f=!1,g;var l=g.value}catch(c){return a.a.a=null,I(a.a,c),K(a)}a.a.a=null;f.call(a.a,l);return K(a)}
function K(a){for(;a.a.b;)try{var b=a.b(a.a);if(b)return a.a.f=!1,{value:b.value,done:!1}}catch(d){a.a.h=void 0,I(a.a,d)}a.a.f=!1;if(a.a.c){b=a.a.c;a.a.c=null;if(b.o)throw b.m;return{value:b.return,done:!0}}return{value:void 0,done:!0}}
function ia(a){this.next=function(b){E(a.a);a.a.a?b=J(a,a.a.a.next,b,a.a.g):(a.a.g(b),b=K(a));return b};this.throw=function(b){E(a.a);a.a.a?b=J(a,a.a.a["throw"],b,a.a.g):(I(a.a,b),b=K(a));return b};this.return=function(b){return ha(a,b)};C();this[Symbol.iterator]=function(){return this}}function ja(a){function b(f){return a.next(f)}function d(f){return a.throw(f)}return new Promise(function(f,g){function l(c){c.done?f(c.value):Promise.resolve(c.value).then(b,d).then(l,g)}l(a.next())})};var L=__dep__colibrio_core_base;function M(a){if(0===a.length)return new Uint8Array(0);if(1===a.length)return a[0];for(var b=0,d=0;d<a.length;d++)b+=a[d].byteLength;b=new Uint8Array(b);for(var f=d=0;f<a.length;f++){var g=a[f];b.set(g,d);d+=g.byteLength}return b}var N=new Uint16Array(1);N[0]=4660;new Uint8Array(N.buffer);function ka(a,b){return"data:"+a+";base64,"+b};function O(){this.a=new Set;this.b=null}O.prototype.createUrlForBlob=function(a){a=URL.createObjectURL(a);this.a.add(a);return a};
O.prototype.createUrl=function(a,b,d){if(L.RuntimePlatform.isBlobUrlSupported()&&!d)b=b?new Blob(a,{type:b}):new Blob(a),a=URL.createObjectURL(b),b={blob:b,url:a},this.a.add(a);else{if(a.every(function(l){return"string"===typeof l}))a="data:"+b+";charset=UTF-8,"+encodeURIComponent(a.join(""));else{d=[];for(var f=0;f<a.length;f++){var g=a[f];"string"===typeof g?d.push(la(this,g)):g instanceof ArrayBuffer?d.push(new Uint8Array(g)):g instanceof Uint8Array?d.push(g):d.push(new Uint8Array(g.buffer,g.byteOffset,
g.byteLength))}a=ka(b,L.Base64.encode(M(d)))}b={blob:null,url:a}}return b};O.prototype.revokeAllUrls=function(){var a=this;this.a.forEach(function(b){return a.revokeUrl(b)});this.a.clear()};O.prototype.revokeUrl=function(a){this.a.delete(a)&&URL.revokeObjectURL(a)};function la(a,b){a.b||(a.b=new TextEncoder);return a.b.encode(b)};function P(a,b){return L.ColibrioError.call(this,"colibrio.core.io",a,b)||this}var Q=L.ColibrioError;P.prototype=aa(Q.prototype);P.prototype.constructor=P;if(w)w(P,Q);else for(var R in Q)if("prototype"!=R)if(Object.defineProperties){var S=Object.getOwnPropertyDescriptor(Q,R);S&&Object.defineProperty(P,R,S)}else P[R]=Q[R];P.a=Q.prototype;function T(a,b){this.c=a;this.a=b;this.b=null}T.prototype.abort=function(){};T.prototype.asText=function(){return Promise.reject(this.a)};T.prototype.asUint8Array=function(){return Promise.reject(this.a)};T.prototype.getMetadata=function(){return this.c};T.prototype.getReadableStream=function(){var a=this;this.b||(this.b=new ReadableStream({start:function(b){b.error(a.a)}}));return this.b};function U(){this.b=this.a=void 0;this.c=new TextDecoder("utf-8")}var V;function W(){V||(V=new U);return V}U.prototype.decode=function(a){return 2<=a.length&&254===a[0]&&255===a[1]&&(void 0===this.a&&(this.a=X("utf-16be")),this.a)?this.a.decode(a):2<=a.length&&255===a[0]&&254===a[1]&&(void 0===this.b&&(this.b=X("utf-16le")),this.b)?this.b.decode(a):this.c.decode(a)};function X(a){try{return new TextDecoder(a)}catch(b){return console.error(b),null}};function Y(a,b,d){this.f=a;this.b=b;this.c=d;this.a=!1}Y.prototype.getMetadata=function(){return this.f};Y.prototype.abort=function(){var a;this.a||(this.a=!0,null===(a=this.c)||void 0===a?void 0:a.call(this))};Y.prototype.asText=function(){return ma(this).then(function(a){return W().decode(a)})};Y.prototype.asUint8Array=function(){return ma(this)};Y.prototype.getReadableStream=function(){return this.b};
function ma(a){var b,d,f,g;return ja(new ia(new fa(function(l){switch(l.b){case 1:b=a.b.getReader(),d=[],f=!1;case 2:if(f){l.b=3;break}return ea(l,b.read());case 4:g=l.h;if(a.a)throw b.cancel().catch(function(c){L.ColibrioError.isColibrioAbortedError(c)||L.Logger.logWarning(c)}),new P(L.ColibrioErrorType.ABORTED);g.value&&d.push(g.value);f=g.done;l.b=2;break;case 3:return l.return(M(d))}})))};function Z(a,b){this.c=a;this.b=null;this.a=b}Z.prototype.abort=function(){this.a&&(this.a=null)};Z.prototype.asText=function(){if(!this.a)return Promise.reject(new P(L.ColibrioErrorType.ABORTED));var a=W().decode(M(this.a));return Promise.resolve(a)};Z.prototype.asUint8Array=function(){return this.a?Promise.resolve(M(this.a)):Promise.reject(new P(L.ColibrioErrorType.ABORTED))};Z.prototype.getMetadata=function(){return this.c};
Z.prototype.getReadableStream=function(){var a=this;if(!this.b){var b=0;this.b=new ReadableStream({cancel:function(){a.a=null},pull:function(d){a.a?(b<a.a.length&&d.enqueue(a.a[b++]),b===a.a.length&&(a.a=null,d.close())):d.error(new P(L.ColibrioErrorType.ABORTED))}})}return this.b};api={BlobUrlManager:O,Endianness:{BIG_ENDIAN:"BIG_ENDIAN",LITTLE_ENDIAN:"LITTLE_ENDIAN"},ErrorResourceResponse:T,IoError:P,IoErrorType:{BYTE_RANGES_NOT_SUPPORTED:"BYTE_RANGES_NOT_SUPPORTED",INVALID_BYTE_RANGE:"INVALID_BYTE_RANGE",READABLE_STREAM_ALREADY_LOCKED:"READABLE_STREAM_ALREADY_LOCKED",RESOURCE_NOT_FOUND:"RESOURCE_NOT_FOUND",RESOURCE_ENCRYPTED:"RESOURCE_ENCRYPTED",RESOURCE_BODY_LOCKED:"RESOURCE_BODY_LOCKED"},MediaType:{APPLICATION_EPUB_ZIP:"application/epub+zip",APPLICATION_HTML:"application/html",
APPLICATION_LPF_ZIP:"application/lpf+zip",APPLICATION_NCX:"application/x-dtbncx+xml",APPLICATION_OPF_PACKAGE:"application/oebps-package+xml",APPLICATION_PDF:"application/pdf",APPLICATION_SMIL:"application/smil+xml",APPLICATION_XHTML:"application/xhtml+xml",APPLICATION_VND_MS_OPENTYPE:"application/vnd.ms-opentype",APPLICATION_XML:"application/xml",APPLICATION_ZIP:"application/zip",AUDIO_AAC:"audio/aac",AUDIO_MPEG:"audio/mpeg",AUDIO_MP4:"audio/mp4",AUDIO_OGG:"audio/ogg",AUDIO_WEBM:"audio/webm",AUDIO_WAV:"audio/wav",
AUDIO_WAVE:"audio/wave",FONT_OTF:"font/opentype",FONT_TTF:"font/truetype",FONT_WOFF:"font/woff",FONT_WOFF2:"font/woff2",IMAGE_BMP:"image/bmp",IMAGE_PNG:"image/png",IMAGE_JPEG:"image/jpeg",IMAGE_SVG:"image/svg+xml",IMAGE_GIF:"image/gif",TEXT_CSS:"text/css",TEXT_JAVASCRIPT:"text/javascript",TEXT_HTML:"text/html",VIDEO_MP4:"video/mp4",VIDEO_WEBM:"video/webm"},MediaTypeCategory:{FONT:"font/*",IMAGE:"image/*",VIDEO:"video/*",AUDIO:"audio/*"},ReadableStreamResourceResponse:Y,Uint8ArrayResourceResponse:Z};var na=new ArrayBuffer(8);new DataView(na);new Uint8Array(na); }).call(__self); return api; }));
