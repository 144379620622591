/**
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without written consent from Colibrio Software AB.
 * 
 * @copyright Colibrio Software AB - All Rights Reserved
 * 
 * This file is part of the Colibrio Reader SDK and is governed by the terms and conditions stated in the
 * LICENSE_SDK.md file.
 * 
 * 
 * @version 3.0.0
 */
'use strict';
(function (r, f) { if (typeof define === 'function' && define.amd) { define(['./colibrio-core-base', './colibrio-core-io-base'], function (a0, a1) { return f(r, a0, a1); }); } else if (typeof module === 'object' && module.exports) { module.exports = f(r, require('./colibrio-core-base'), require('./colibrio-core-io-base')); } else { r.colibrio = r.colibrio || {}; r.colibrio.core = r.colibrio.core || {}; r.colibrio.core.xml = f(r, colibrio.core.base, colibrio.core.io.base); } }(typeof self !== 'undefined' && self ? self : typeof window !== 'undefined' && window ? window : typeof global !== 'undefined' && global ? global : this, function(__self,__dep__colibrio_core_base,__dep__colibrio_core_io_base) { var api = {}; (function() { 'use strict';function p(a){var b=0;return function(){return b<a.length?{done:!1,value:a[b++]}:{done:!0}}}function t(a){var b="undefined"!=typeof Symbol&&Symbol.iterator&&a[Symbol.iterator];return b?b.call(a):{next:p(a)}}var v="function"==typeof Object.create?Object.create:function(a){function b(){}b.prototype=a;return new b},w;
if("function"==typeof Object.setPrototypeOf)w=Object.setPrototypeOf;else{var x;a:{var y={l:!0},z={};try{z.__proto__=y;x=z.l;break a}catch(a){}x=!1}w=x?function(a,b){a.__proto__=b;if(a.__proto__!==b)throw new TypeError(a+" is not extensible");return a}:null}var A=w,B="undefined"!=typeof window&&window===this?this:"undefined"!=typeof global&&null!=global?global:this,D="function"==typeof Object.defineProperties?Object.defineProperty:function(a,b,d){a!=Array.prototype&&a!=Object.prototype&&(a[b]=d.value)};
function E(a,b){if(b){var d=B;a=a.split(".");for(var g=0;g<a.length-1;g++){var f=a[g];f in d||(d[f]={});d=d[f]}a=a[a.length-1];g=d[a];b=b(g);b!=g&&null!=b&&D(d,a,{configurable:!0,writable:!0,value:b})}}
E("Promise",function(a){function b(c){this.b=0;this.g=void 0;this.a=[];var e=this.c();try{c(e.resolve,e.reject)}catch(h){e.reject(h)}}function d(){this.a=null}function g(c){return c instanceof b?c:new b(function(e){e(c)})}if(a)return a;d.prototype.b=function(c){if(null==this.a){this.a=[];var e=this;this.c(function(){e.g()})}this.a.push(c)};var f=B.setTimeout;d.prototype.c=function(c){f(c,0)};d.prototype.g=function(){for(;this.a&&this.a.length;){var c=this.a;this.a=[];for(var e=0;e<c.length;++e){var h=
c[e];c[e]=null;try{h()}catch(k){this.f(k)}}}this.a=null};d.prototype.f=function(c){this.c(function(){throw c;})};b.prototype.c=function(){function c(k){return function(n){h||(h=!0,k.call(e,n))}}var e=this,h=!1;return{resolve:c(this.s),reject:c(this.f)}};b.prototype.s=function(c){if(c===this)this.f(new TypeError("A Promise cannot resolve to itself"));else if(c instanceof b)this.u(c);else{a:switch(typeof c){case "object":var e=null!=c;break a;case "function":e=!0;break a;default:e=!1}e?this.o(c):this.i(c)}};
b.prototype.o=function(c){var e=void 0;try{e=c.then}catch(h){this.f(h);return}"function"==typeof e?this.v(e,c):this.i(c)};b.prototype.f=function(c){this.j(2,c)};b.prototype.i=function(c){this.j(1,c)};b.prototype.j=function(c,e){if(0!=this.b)throw Error("Cannot settle("+c+", "+e+"): Promise already settled in state"+this.b);this.b=c;this.g=e;this.m()};b.prototype.m=function(){if(null!=this.a){for(var c=0;c<this.a.length;++c)l.b(this.a[c]);this.a=null}};var l=new d;b.prototype.u=function(c){var e=this.c();
c.h(e.resolve,e.reject)};b.prototype.v=function(c,e){var h=this.c();try{c.call(e,h.resolve,h.reject)}catch(k){h.reject(k)}};b.prototype.then=function(c,e){function h(q,r){return"function"==typeof q?function(u){try{k(q(u))}catch(C){n(C)}}:r}var k,n,m=new b(function(q,r){k=q;n=r});this.h(h(c,k),h(e,n));return m};b.prototype.catch=function(c){return this.then(void 0,c)};b.prototype.h=function(c,e){function h(){switch(k.b){case 1:c(k.g);break;case 2:e(k.g);break;default:throw Error("Unexpected state: "+
k.b);}}var k=this;null==this.a?l.b(h):this.a.push(h)};b.resolve=g;b.reject=function(c){return new b(function(e,h){h(c)})};b.race=function(c){return new b(function(e,h){for(var k=t(c),n=k.next();!n.done;n=k.next())g(n.value).h(e,h)})};b.all=function(c){var e=t(c),h=e.next();return h.done?g([]):new b(function(k,n){function m(u){return function(C){q[u]=C;r--;0==r&&k(q)}}var q=[],r=0;do q.push(void 0),r++,g(h.value).h(m(q.length-1),n),h=e.next();while(!h.done)})};return b});
E("Promise.prototype.finally",function(a){return a?a:function(b){return this.then(function(d){return Promise.resolve(b()).then(function(){return d})},function(d){return Promise.resolve(b()).then(function(){throw d;})})}});var F=__dep__colibrio_core_base;var G=__dep__colibrio_core_io_base;var H=new Uint16Array(1);H[0]=4660;new Uint8Array(H.buffer);function I(a,b,d){a=F.ColibrioError.call(this,"colibrio.core.xml",a,d)||this;a.parserErrorDocument=b;return a}var J=F.ColibrioError;I.prototype=v(J.prototype);I.prototype.constructor=I;if(A)A(I,J);else for(var K in J)if("prototype"!=K)if(Object.defineProperties){var L=Object.getOwnPropertyDescriptor(J,K);L&&Object.defineProperty(I,K,L)}else I[K]=J[K];I.a=J.prototype;function M(){}M.prototype.parseHtmlString=function(a,b){a=N().parseFromString(a,G.MediaType.TEXT_HTML);O(a,b);return a};M.prototype.parseXhtmlString=function(a,b){a=N().parseFromString(a,G.MediaType.APPLICATION_XHTML);O(a,b);return a};M.prototype.parseXmlString=function(a,b){a=N().parseFromString(a,G.MediaType.APPLICATION_XML);O(a,b);return a};function O(a,b){P(a.documentElement,b);P(a.documentElement.firstElementChild,b);a.body&&P(a.body.firstElementChild,b)}
function P(a,b){if(a&&"parsererror"===a.localName)throw new I("DOM_PARSE_ERROR",a.ownerDocument,'"'+b+'": '+(a.textContent||""));}var Q;function N(){Q||(Q=new DOMParser);return Q};function R(a){this.b=a;this.f="";this.c=this.a=0;this.g=a.length}function S(a){for(;62!==a.b.charCodeAt(a.a++););}
function T(a,b){var d=a.a;for(var g;(g=a.b.charCodeAt(++a.a))&&9!==g&&10!==g&&12!==g&&32!==g&&47!==g&&62!==g;);d=b?a.b.slice(d,a.a):"";g=!0;for(var f=!1;g;){var l=a.b.charCodeAt(a.a++);if(34===l||39===l)for(var c=a,e=c.b.charCodeAt(c.a++);e!==l;)e=c.b.charCodeAt(c.a++);else 47===l?(l=a.b.charCodeAt(a.a),62===l&&(f=!0,g=!1,a.a++)):62===l?g=!1:l||(g=!1)}b&&f&&!U.has(d.toLowerCase())&&(a.f+=a.b.slice(a.c,a.a-2)+"></"+d+">",a.c=a.a)}var U=new Set("area base br col embed hr img input link meta param source track wbr".split(" "));function V(a,b,d){this.b=this.a=this.c=null;this.f="";var g=new M,f=!1;if(!d){if((d=/^([\s\S]+?)<html/i.exec(a))&&d[1]){d=d[1];var l=/^<\?xml[\s\S]+?\?>/.exec(d);l&&l[0]&&(this.f=l[0]);/<!DOCTYPE\s+html\s*[>\[]/i.test(d)||(a=a.replace(/^[\s\S]+?<html/i,this.f+"<!DOCTYPE html>\n<html"))}try{var c=g.parseXhtmlString(a,b);"html"===c.documentElement.localName&&c.documentElement.style?this.b=c:F.Logger.logWarning(new I("INVALID_XHTML_DOCUMENT",null,'path="'+b+'" rootTagName="'+c.documentElement.localName+
'"'))}catch(u){F.Logger.logWarning(u),F.Logger.logWarning(new I("INVALID_XHTML_DOCUMENT",null,'path="'+b+'"')),this.a=u.parserErrorDocument||null,f=!0}}if(!this.b)try{if(f){F.Logger.logInfo("Falling back to HTML parser for: "+b);for(var e=new R(a);e.a<e.g;)if(60===e.b.charCodeAt(e.a++)){var h=e.b.charCodeAt(e.a);if(65<=h&&90>=h||97<=h&&122>=h)T(e,!0);else if(47===h){e.a++;f=e;var k=f.b.charCodeAt(f.a);65<=k&&90>=k||97<=k&&122>=k?T(f,!1):62===k?f.a++:S(f)}else if(63===h)S(e);else if(33===h){e.a++;
f=e;var n=f.b.charCodeAt(f.a);if(45===n&&45===f.b.charCodeAt(f.a+1))for(f.a+=2,c=!0,d=1;c;){var m=f.b.charCodeAt(f.a++);switch(d){case 1:45===m?d=2:62===m?c=!1:(f.a--,d=3);break;case 2:45===m?d=8:62===m?c=!1:(f.a--,d=3);break;case 3:60===m?d=4:45===m?d=7:"undefined"===typeof m&&(c=!1);break;case 4:33===m?d=5:60!==m&&(f.a--,d=3);break;case 5:45===m?d=6:(f.a--,d=3);break;case 6:45===m?d=8:(f.a--,d=7);break;case 7:45===m?d=8:(f.a--,d=3);break;case 8:62===m?c=!1:33===m?d=9:45!==m&&(f.a--,d=3);break;case 9:45===
m?d=7:62===m?c=!1:(f.a--,d=3)}}else if(91===n&&"[CDATA["===f.b.slice(f.a,f.a+7))for(f.a+=7,c=!0;c;){var q=f.b.charCodeAt(f.a++);93===q&&93===f.b.charCodeAt(f.a)&&62===f.b.charCodeAt(f.a+1)?(f.a+=2,c=!1):"undefined"===typeof q&&(c=!1)}else S(f)}else e.a++}a=0===e.c?e.b:e.f+e.b.slice(e.c)}var r=g.parseHtmlString(a,b);"html"===r.documentElement.localName&&r.documentElement.style&&(this.c=r)}catch(u){F.Logger.logError(u),F.Logger.logError(new I("INVALID_HTML_DOCUMENT",null,'path="'+b+'"')),this.a||(this.a=
u.parserErrorDocument||null)}}V.prototype.getDocument=function(){return this.b||this.c};V.prototype.getParserError=function(){return this.a};V.prototype.getXmlHeader=function(){return this.f};V.prototype.isHtmlDocument=function(){return null!==this.c};V.prototype.isXhtmlDocument=function(){return null!==this.b};function W(a){return a.replace(/["&'<>]/g,function(b){switch(b){case '"':return"&quot;";case "&":return"&amp;";case "'":return"&apos;";case "<":return"&lt;";case ">":return"&gt;"}return""})}
function X(a,b,d){switch(b.nodeType){case 1:d.push("<",b.tagName);var g=b.attributes;if(0<g.length)for(var f=0;f<g.length;f++){var l=g[f];d.push(" ",W(l.name),'="',W(l.value),'"')}a:{if(b.namespaceURI===F.XmlNamespace.HTML)switch(b.localName){case "area":case "base":case "br":case "col":case "command":case "embed":case "hr":case "img":case "input":case "keygen":case "link":case "menuitem":case "meta":case "param":case "source":case "track":case "wbr":g=!0;break a}g=!1}if(g)d.push(" />");else{d.push(">");
g="script"===b.localName;f=b.childNodes;for(l=0;l<f.length;l++){var c=f[l];!g||3!==c.nodeType&&4!==c.nodeType?X(a,c,d):d.push(c.data.replace(/<\/script>/g,"&lt;/script>"))}d.push("</"+b.tagName+">")}break;case 3:d.push(W(b.data));break;case 4:d.push(W(b.data));break;case 8:d.push("\x3c!--",W(b.data.replace(/--/g,"- "))||" ","--\x3e");break;case 9:b=b.childNodes;for(g=0;g<b.length;g++)X(a,b[g],d);break;case 10:b.name&&(d.push("<!DOCTYPE ",b.name),b.publicId&&d.push(' PUBLIC "',b.publicId,'"'),b.systemId&&
d.push(' "',b.systemId,'"'),d.push(">"));break;default:throw new I("UNSUPPORTED_NODE_TYPE",null,""+b.nodeType);}};var aa=new function(){},ba=new XMLSerializer;function Y(){}Y.prototype.serializeXml=function(a){var b=!0;a&&9===a.nodeType&&"HTML"===a.documentElement.tagName?b=!1:a&&1===a.nodeType&&a.namespaceURI===F.XmlNamespace.HTML&&a.tagName!==a.tagName.toLowerCase()&&(b=!1);b?a=ba.serializeToString(a):(b=[],X(aa,a,b),a=b.join(""));return a};api={CoreXmlError:I,CoreXmlErrorType:{DOM_PARSE_ERROR:"DOM_PARSE_ERROR",INVALID_HTML_DOCUMENT:"INVALID_HTML_DOCUMENT",INVALID_XHTML_DOCUMENT:"INVALID_XHTML_DOCUMENT",UNSUPPORTED_NODE_TYPE:"UNSUPPORTED_NODE_TYPE"},DomParser:M,XhtmlParser:V,XmlSerializer:Y};var Z=new ArrayBuffer(8);new DataView(Z);new Uint8Array(Z); }).call(__self); return api; }));
